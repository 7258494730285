import { FC } from 'react';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import { ShowModalContainer } from '../css';
import Button from 'shared/components/button';

const ShowModal: FC<{
	status: boolean;
	setStatus: (status: boolean) => void;
}> = ({ status, setStatus }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });

	const onCloseClickHandler = () => {
		onClose();
	};

	const handleChoose = () => {
		onClose();
	};

	return (
		<Modal isBottomSheet status={_status} onClose={onCloseClickHandler} title='' backgroundColor='#4A37A9'>
			<ShowModalContainer>
				<img src={'/img/show-modal/icon.webp'} alt='' className='header_img' />
				<h1>Join FjordFoundry Public Sale - NO KYC</h1>
				<h3>
					Don't miss out
					<br />
					Join PinEye Public Sale{' '}
				</h3>
				<Button
					href='https://app.fjordfoundry.com/token-sales/0x39514f96B55a84011996c3C8678510a86960fFbE'
					target='_blank'
					variant='primary'
					onClick={handleChoose}
				>
					Visit FjordFoundry website
				</Button>
			</ShowModalContainer>
		</Modal>
	);
};

export default ShowModal;
