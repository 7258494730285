import { useState } from 'react';
import { ChristmasContainer } from './christmas.style';
import ChristmasModal from './modal';
import { POSTGetReward } from 'api/v3/christmas';
import { useAppDispatch } from 'shared/hooks/redux-helper';
import { changeBalance, setInitialLevel } from 'store/reducers/profile';
import { updateChristmas } from 'store/query';

const Christmas = () => {
	const [boxOpen, setBoxOpen] = useState<number | null>(null);
	const [state, setState] = useState(false);
	const [profit, setProfit] = useState(0);
	const dispatch = useAppDispatch();
	const onCloseModal = (state: boolean) => {
		setState(state);
	};

	const handleClick = (index: number) => {
		setBoxOpen(index + 1);
		if (boxOpen === null) {
			POSTGetReward().then(res => {
				setProfit(res.reward);
				setTimeout(() => {
					setState(true);
				}, 2000);
				dispatch(changeBalance(res));
				dispatch(setInitialLevel(res.level));
				dispatch(updateChristmas({ canGetReward: false }));
			});
		}
	};
	return (
		<ChristmasContainer>
			<img src='/img/christmas/bg.webp' alt='' className='bg' />
			<h1>Tap a Gift to Unwrap Your Christmas Surprise!</h1>
			<div className='gift_container'>
				{Array.from({ length: 6 }).map((_, index) => (
					<div key={index} className='gift' onClick={boxOpen === null ? () => handleClick(index) : undefined}>
						<img
							src={`/img/christmas/${index + 1}.webp`}
							alt=''
							className={`head ${index + 1 === boxOpen ? 'head_move' : ''}`}
						/>
						<img src={`/img/christmas/${index + 1}${index + 1}.webp`} alt='' className='base' />
					</div>
				))}
			</div>
			{boxOpen !== null && state && <ChristmasModal status={state} setStatus={onCloseModal} profit={profit} />}
		</ChristmasContainer>
	);
};
export default Christmas;
