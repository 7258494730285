// import { useEffect, useState } from 'react';
import { WalletContainer } from './css';
import { Helmet } from 'react-helmet';
import { CountDown, List } from './components';

const Wallet = () => {
	return (
		<WalletContainer>
			<Helmet>
				<title>PinEye | Wallet</title>
			</Helmet>
			<header>
				<img src='/img/wallet-v2/header-bg.webp' alt='' className='header_bg' />
				<img src='/img/wallet-v2/header-hero.webp' alt='' className='header_hero' />

				<div className='header_overlay' />
			</header>

			<div className='content'>
				<div className='content_timer'>
					<div className='timer_container'>
						<p className='timer_title'>TGE Listing</p>
						<CountDown date={1737979200000} />
					</div>
				</div>
			</div>
			<List />
		</WalletContainer>
	);
};
export default Wallet;
