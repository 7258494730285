import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const OverviewContainer = styled.section(
	({ theme }) => css`
		display: grid;
		grid-template-columns: 1.2fr 98px 1.2fr;
		grid-template-rows: 64px;
		padding: 16px 16px;
		color: ${theme.colors.text.primary};
		position: relative;
		justify-content: center;
		align-items: flex-start;
		z-index: 5;
		.line {
			width: 100%;
			height: 100%;
			background-image: url('/img/home-avatar/line.webp');
			background-size: contain;
			background-repeat: no-repeat;
			z-index: 6;
			position: absolute;
			top: 60%;
			inset-inline-start: 1%;
			margin: auto;
		}
		.name_container {
			background-image: url('/img/home-avatar/outter-l.webp');
			background-size: contain;
			background-position: top left;
			background-repeat: no-repeat;
			width: 110%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 5%;
			.name {
				background-image: url('/img/home-avatar/inner-r.webp');
				background-size: contain;
				background-position: top center;
				background-repeat: no-repeat;
				width: 70%;
				height: 100%;
				display: flex;
				align-items: flex-start;
				padding-top: 5px;
				justify-content: space-around;
				position: relative;
				p {
					font-size: 12px;
					font-weight: bold;
					line-height: 13px;
					text-align: left;
					display: flex;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 70px;
					margin-left: 15px;
				}
				.head {
					width: 30px;
					position: absolute;
					top: -10px;
					inset-inline-start: -5%;
				}
			}
		}
		.tech {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 1px;
			padding-top: 9px;
			justify-self: center;
			.icon {
				position: absolute;
				top: 27px;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 99px;
				height: 84px;
			}
			p {
				font-size: 11px;
				font-weight: bold;
				line-height: 14px;
				text-align: center;
				position: relative;
				top: 23px;
			}
			span {
				font-size: 15px;
				font-weight: bold;
				line-height: 21px;
				text-align: center;
				position: relative;
				top: 22px;
			}
		}
		.gem_container {
			background-image: url('/img/home-avatar/outter-r.webp');
			background-size: contain;
			background-position: top left;
			background-repeat: no-repeat;
			width: 110%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 5%;
			justify-self: flex-end;
			.name {
				background-image: url('/img/home-avatar/inner-l.webp');
				background-size: contain;
				background-position: top center;
				background-repeat: no-repeat;
				width: 70%;
				height: 100%;
				display: flex;
				align-items: flex-start;
				padding-top: 5px;
				justify-content: space-around;
				position: relative;
				p {
					font-size: 12px;
					font-weight: bold;
					line-height: 15px;
					text-align: left;
					display: flex;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 70px;
					margin-right: 15px;
				}
				.head {
					width: 30px;
					position: absolute;
					top: -10px;
					inset-inline-end: -5%;
				}
			}
		}
		@media (max-width: 350px) {
			.line {
				top: 66%;
				inset-inline-start: 1.3%;
			}
			.name_container {
				.name {
					padding-top: 3px;
					p {
						max-width: 60px;
						margin-left: 0;
					}
					.head {
						inset-inline-start: -15%;
						width: 25px;
					}
				}
			}
			.gem_container {
				.name {
					padding-top: 3px;
					p {
						max-width: 60px;
						margin-right: 0;
					}
					.head {
						inset-inline-end: -15%;
						width: 25px;
					}
				}
			}
		}
	`,
);
