import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//@ts-ignore
import { Socket } from 'socket.io-client';

export interface ISocketState {
	isConnected: boolean;
	socket: Socket | null;
	balance: {
		body: {
			Balance: number;
			ChatId: number;
			FactorId: number;
			Value: number;
		};
	};
	notification: {
		body: {
			ChatId: number;
			Type: string;
			MetaData: string;
		};
	};
}

const initialState: ISocketState = {
	isConnected: false,
	socket: null,
	balance: {
		body: {
			Balance: 0,
			ChatId: 0,
			FactorId: 0,
			Value: 0,
		},
	},
	notification: {
		body: {
			ChatId: 0,
			Type: '',
			MetaData: '',
		},
	},
};

const SocketSlice = createSlice({
	name: 'socket',
	initialState,
	reducers: {
		setConnection: (state, action: PayloadAction<boolean>) => {
			state.isConnected = action.payload;
		},
		setBalance: (state, action: PayloadAction<typeof initialState.balance>) => {
			state.balance = action.payload;
		},
		setNotification: (state, action: PayloadAction<typeof initialState.notification>) => {
			state.notification = action.payload;
		},
		clearNotification: state => {
			state.notification = initialState.notification;
		},
		resetSocket: () => {
			return initialState;
		},
	},
});

export const { setConnection, setBalance, setNotification, clearNotification, resetSocket } = SocketSlice.actions;

export default SocketSlice.reducer;
