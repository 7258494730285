import { FC } from 'react';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import { SelectLangModalContainer } from '../css/select-lang-modal.style';
import Button from 'shared/components/button';

interface ISelectLangProps {
	selectedLang: 'arabic' | 'english' | 'french' | 'indonesian' | 'persian' | 'russian';
	setSelectedLang: (selectedLang: 'arabic' | 'english' | 'french' | 'indonesian' | 'persian' | 'russian') => void;
	status: boolean;
	setStatus: (status: boolean) => void;
	setSound: (sound: boolean) => void;
}

const SelectLangModal: FC<ISelectLangProps> = ({ selectedLang, setSelectedLang, status, setStatus, setSound }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });

	const handleClose = () => {
		onClose();
		setSound(true);
	};
	return (
		<Modal isBottomSheet backgroundImage='/img/game-card/modal-bg.webp' status={_status} onClose={handleClose} title={''}>
			<SelectLangModalContainer>
				<h4>Select your Language</h4>
				<div className='lang_list'>
					<label
						className={`lang_item ${selectedLang === 'english' ? 'selected' : ''}`}
						onClick={() => setSelectedLang('english')}
					>
						<span>English</span>
						<div>
							<span>🇬🇧</span>
							<input
								type='radio'
								name='language'
								value='english'
								checked={selectedLang === 'english'}
								onChange={e =>
									setSelectedLang(
										e.target.value as 'arabic' | 'english' | 'french' | 'indonesian' | 'persian' | 'russian',
									)
								}
							/>
						</div>
					</label>
					<label className={`lang_item ${selectedLang === 'arabic' ? 'selected' : ''}`} onClick={() => setSelectedLang('arabic')}>
						<span>Arabic</span>
						<div>
							<span>🇸🇦</span>
							<input
								type='radio'
								name='language'
								value='arabic'
								checked={selectedLang === 'arabic'}
								onChange={e =>
									setSelectedLang(
										e.target.value as 'arabic' | 'english' | 'french' | 'indonesian' | 'persian' | 'russian',
									)
								}
							/>
						</div>
					</label>
					<label className={`lang_item ${selectedLang === 'french' ? 'selected' : ''}`} onClick={() => setSelectedLang('french')}>
						<span>French</span>
						<div>
							<span>🇫🇷</span>
							<input
								type='radio'
								name='language'
								value='french'
								checked={selectedLang === 'french'}
								onChange={e =>
									setSelectedLang(
										e.target.value as 'arabic' | 'english' | 'french' | 'indonesian' | 'persian' | 'russian',
									)
								}
							/>
						</div>
					</label>
					<label
						className={`lang_item ${selectedLang === 'russian' ? 'selected' : ''}`}
						onClick={() => setSelectedLang('russian')}
					>
						<span>Russian</span>
						<div>
							<span>🇷🇺</span>
							<input
								type='radio'
								name='language'
								value='russian'
								checked={selectedLang === 'russian'}
								onChange={e =>
									setSelectedLang(
										e.target.value as 'arabic' | 'english' | 'french' | 'indonesian' | 'persian' | 'russian',
									)
								}
							/>
						</div>
					</label>
					<label
						className={`lang_item ${selectedLang === 'indonesian' ? 'selected' : ''}`}
						onClick={() => setSelectedLang('indonesian')}
					>
						<span>Indonesian</span>
						<div>
							<span>🇮🇩</span>
							<input
								type='radio'
								name='language'
								value='indonesian'
								checked={selectedLang === 'indonesian'}
								onChange={e =>
									setSelectedLang(
										e.target.value as 'arabic' | 'english' | 'french' | 'indonesian' | 'persian' | 'russian',
									)
								}
							/>
						</div>
					</label>
					<label
						className={`lang_item ${selectedLang === 'persian' ? 'selected' : ''}`}
						onClick={() => setSelectedLang('persian')}
					>
						<span>Persian</span>
						<div>
							<span>🇮🇷</span>
							<input
								type='radio'
								name='language'
								value='persian'
								checked={selectedLang === 'persian'}
								onChange={e =>
									setSelectedLang(
										e.target.value as 'arabic' | 'english' | 'french' | 'indonesian' | 'persian' | 'russian',
									)
								}
							/>
						</div>
					</label>
				</div>
				<Button variant='primary' className='btn' onClick={handleClose}>
					Continue
				</Button>
			</SelectLangModalContainer>
		</Modal>
	);
};

export default SelectLangModal;
