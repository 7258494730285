import { useEffect, useState } from 'react';
import { VipContainer } from './css';
import { CopyOutline } from 'shared/components/icons';
import Icon from 'shared/components/icon';
import { flush, injectGlobal } from '@emotion/css';
import { useVipQuery } from 'store/query';
import toast from 'react-hot-toast';

const Vip = () => {
	const { data } = useVipQuery();

	const [verified, setVerified] = useState<boolean>();
	const onCopyClickHandler = () => {
		if (data !== null && data !== undefined) {
			navigator.clipboard.writeText(data?.address).then(() => {
				toast.success('Copied to clipboard successfully!');
			});
		}
	};
	useEffect(() => {
		if (data !== null && data !== undefined) {
			setVerified(data.isVip);
		}
		injectGlobal`
				body {
					background-color: #002554;
				}
			`;

		return () => flush();
	}, [data]);
	return (
		<VipContainer>
			<img src='/img/campaign/vip-b.webp' alt='' className='head' />
			<div className='light' />
			{data !== null && data !== undefined ? (
				<div className='connected_content'>
					{verified ? <h2>Welcome to the VIP Member Club!</h2> : <h1>VIP Member</h1>}
					{verified ? (
						<p className='des'>
							You are truly valued, and we sincerely appreciate your support. Thank you for being an essential part of our
							journey!
						</p>
					) : (
						<>
							<p className='text'>
								Unlock exclusive perks with our <span>VIP</span> membership:
							</p>
							<div className='des'>
								<img src='/img/campaign/$.webp' alt='' className='icon' />
								<span>
									30% More Extra <strong>Tokens</strong>
								</span>
							</div>
							<div className='des'>
								<img src='/img/campaign/cal.webp' alt='' className='icon' />
								<span>Prior Airdrop Access at TGE</span>
							</div>
						</>
					)}

					<div className='tick'>
						<Icon name={verified ? 'tick' : 'warning'} className='icon' />
						{verified ? <p>You are Verified as a VIP Member.</p> : <p>You are not verified as a VIP member.</p>}
					</div>
					<div className='copy_div'>
						<p>{data.address}</p>
						<div className='copy_icon' onClick={onCopyClickHandler}>
							<CopyOutline />
						</div>
					</div>
					{!verified && <p className='visit'>Visit PinEye.io and securely connect your wallet directly to your browser.</p>}
				</div>
			) : (
				<div className='content'>
					<h1>VIP Member</h1>
					<p className='text'>
						Unlock exclusive perks with our <span>VIP</span> membership:
					</p>
					<div className='des'>
						<img src='/img/campaign/$.webp' alt='' className='icon' />
						<span>
							30% More Extra <strong>Tokens</strong>
						</span>
					</div>
					<div className='des'>
						<img src='/img/campaign/cal.webp' alt='' className='icon' />
						<span>Prior Airdrop Access at TGE</span>
					</div>

					<div className='copy_div'>
						<p>No wallet address</p>
						<div className='copy_icon' onClick={onCopyClickHandler}>
							<CopyOutline />
						</div>
					</div>

					<p className='visit'>Visit PinEye.io and securely connect your wallet directly to your browser.</p>
				</div>
			)}
		</VipContainer>
	);
};

export default Vip;
