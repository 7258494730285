import requestHandler from 'shared/utils/request-handler';
import { IWalletResponse, IHasVipWallet } from './wallet.dto';
const BASE_URL = '/api/v1/Wallet';

export const POSTConnectWallet = async (walletType: string, address: string) => {
	return requestHandler
		.call<{ message: string }>({ url: `${BASE_URL}/ConnectWallet`, method: 'post', data: { walletType, address } })
		.then(res => res.data);
};

export const GETAllWallets = async () => {
	return requestHandler.call<IWalletResponse[]>({ url: `${BASE_URL}/GetAll`, method: 'get' }).then(res => res.data);
};

export const POSTDisconnectWallet = async (walletType: string, address: string) => {
	return requestHandler
		.call<{ message: string }>({ url: `${BASE_URL}/DisconnectWallet`, method: 'post', data: { walletType, address } })
		.then(res => res.data);
};

export const GETHasVipWallet = async () => {
	return requestHandler.call<IHasVipWallet | null>({ url: `${BASE_URL}/HasVipWallet`, method: 'get' }).then(res => res.data);
};
