import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CampaignBybitContainer = styled.div(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		padding-top: 46px;
		padding-bottom: 150px;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
		grid-template-rows:141px 55px 255px 30px 32px;
		gap: 6px;
		justify-content: space-around;
		z-index: 2;
		height: 100%;
		background: url(/img/campaign/bg-bybit.webp);
		background-position: top left;
		background-size: cover;
		background-repeat: no-repeat;
		overflow-y: auto;
		.header_img {
			width: 128px;
			position: relative;
			left: 0;
			right: 0;
			margin: 0 auto;
			top: 0px;
		}
		h1 {
			font-size: 21px;
			font-weight: 700;
			line-height: 26px;
			text-align: center;
			z-index: 2;
			position: relative;
			padding-bottom: 20px;
		}
		.content_img {
			width: 100%;
		}
	 
			.prize {
				font-size: 25px;
				font-weight: 600;
				line-height: 31px;
				text-align: center;
			}
			.token {
				font-size: 22px;
				font-weight: 600;
				line-height: 27px;
				text-align: center;
				span {
					color: #fece01;
				}
			}
		a {
			background: #f5c907 !important;
			color: #000;
			position: fixed;
			bottom: 15vh;
			left: 0;
			right: 0;
			margin: auto;
			width: 82%;
			z-index: 5;
			margin-top: 10px;
		}
		@media (max-height: 688px) {
			.btn {
				bottom: 16vh;
				z-index: 5;
			}
		}
	`,
);
