import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SingleCardContainer = styled.section<{ selected?: boolean; category: string }>(
	({ selected, category }) => css`
		border-radius: 12px;
		background-color: ${selected ? '#d4f4dd' : '#fff'};
		box-shadow: ${selected ? '0 0 10px #34c759' : '0 0 4px rgba(0, 0, 0, 0.1)'};
		padding: 8px;
		position: relative;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.top {
			position: absolute;
			top: 8px;
			left: 8px;

			.top_num {
				/* background: ${category === 'cloth' ? '#ffeb3b' : '#8bc34a'}; */
				border-radius: 50%;
				border: 1px solid #333;
				width: 35px;
				height: 35px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 11px;
				font-weight: bolder;
			}
		}

		.icon {
			width: 82px;
			height: 89px;

			border-radius: 8px;
		}

		.status {
			position: absolute;
			font-size: 14px;
			top: 40%;
			inset-inline-start: 50%;
			transform: translate(-50%, -50%);
			.status_icon {
				width: 35px;
			}
			&.locked {
				color: #f44336;
			}

			&.current {
				color: #34c759;
			}
		}

		.content {
			text-align: center;
			margin-top: 8px;
			width: 100%;
			h3 {
				font-size: 11px;
				font-weight: bold;
				color: #fff;
				background: #000;
				padding: 3px 0 1px;
				width: 100%;
				border-radius: 50px;
				&.selected {
					background: #34c759;
					color: #000;
				}
			}

			.price {
				margin-top: 4px;
				font-size: 12px;
				color: #555;

				span {
					margin-right: 8px;
				}
			}

			.current {
				color: #000;
				font-size: 11px;
				font-weight: bold;
				margin-top: 4px;
			}
		}
	`,
);
