import requestHandler from 'shared/utils/request-handler';
import { IGetAsset, IPostBuyAsset, IPostSelectAsset } from './assets.dto';

const BASE_URL = '/api/v1/Assets';

export const GETAssets = async (type: string) => {
	return requestHandler.call<IGetAsset[]>({ url: `${BASE_URL}?type=${type}`, method: 'get' }).then(res => res.data);
};

export const POSTBuyAsset = async (assetId: number) => {
	return requestHandler.call<IPostBuyAsset>({ url: `${BASE_URL}/${assetId}/buy`, method: 'post' }).then(res => res.data);
};

export const POSTSelectAsset = async (assetId: number) => {
	return requestHandler.call<IPostSelectAsset>({ url: `${BASE_URL}/${assetId}/select`, method: 'post' }).then(res => res.data);
};
