import { css } from '@emotion/react';
import styled from '@emotion/styled';
export const HomeContainer = styled.div(
	() => css`
		padding: 0 0 40px;
		overflow-x: hidden;
		min-height: 100vh;
		height: 100%;
		position: relative;
		background-color: #000;
		-webkit-overflow-scrolling: touch;
		.christmas_img_container {
			position: fixed;
			top: 60%;
			left: 25%;
			transform: translate(-50%, -50%);
			z-index:3;
			animation: shake 0.5s ease-in-out infinite;
			animation-delay: 2s; 

			img {
				width: 110px;
			}
		}

		@keyframes shake {
			0%,
			100% {
				transform: translate(-50%, -50%);
			}
			25% {
				transform: translate(-52%, -50%);
			}
			75% {
				transform: translate(-48%, -50%);
			}
		}
		.base_img {
			position: fixed;
			bottom: 0px;
			right: 0;
			left: 0;
			margin: auto;
			pointer-events: none;
			touch-action: none;
			z-index: 1;
			width: 100%;
		}
		.rotate_img {
			position: fixed;
			top: -1vh;
			left: -50%;
			right: 0;
			margin: auto;
			width: 200%;
			z-index: 0;
			animation: rotate 60s linear infinite;
			overflow: hidden;
		}

		.video_bg {
			position: absolute;
			top: 0%;
			z-index: 1;
			margin: auto;
			left: 0%;
			right: 0%;
			width: 100%;
		}
		@keyframes rotate {
			from {
				transform: rotate(0deg);
			}
			to {
				transform: rotate(360deg);
			}
		}
		@media (max-height: 882px) and (max-width: 350px) {
			.rotate_img {
				top: -8vh;
				width: 200%;
				left: -50%;
			}
			.base_img {
				bottom: 10vh;
			}
		}
		@media (max-height: 844px) {
			.rotate_img {
				top: -8vh;
				width: 200%;
				left: -50%;
			}
		}
		@media (max-height: 750px) {
			.rotate_img {
				top: -11vh;
				width: 200%;
			}
			.base_img {
				bottom: -5vh;
			}
		}
		@media (max-height: 688px) {
			.rotate_img {
				top: -10vh;
				width: 200%;
			}
			.base_img {
				bottom: -10vh;
			}
		}
		@media (max-height: 650px) {
			.base_img {
				bottom: -5vh;
			}
			.rotate_img {
				top: -5vh;
				width: 200%;
			}
		}
		@media (max-height: 620px) {
			.base_img {
				bottom: -6vh;
			}
			.rotate_img {
				top: -9vh;
				width: 200%;
			}
		}
		@media (max-height: 590px) {
			/* ali khoda phone height */
			.base_img {
				bottom: -19vh;
			}
			.rotate_img {
				top: -9vh;
				width: 200%;
			}
		}
		@media (max-height: 555px) {
			.base_img {
				bottom: -20vh;
			}
			.rotate_img {
				width: 200%;
				top: -9vh;
			}
		}
	`,
);
