import Button from 'shared/components/button';
import { CampaignBybitContainer } from './campaign.style';

const CampaignBybit = () => {
	return (
		<CampaignBybitContainer>
			<img src='/img/new-home/bybit.webp' alt='' className='header_img' />
			<h1>Bybit Campaign</h1>
			<img src='img/campaign/bybit-c.webp' alt='' className='content_img' />
 				<p className='prize'>Prize Pool:</p>
				<p className='token'>
					4,500,000 <span>PinEye Token</span>
				</p>
 			<Button target='_blank' href='https://www.bybit.com/en/web3/airdrop/activity?activityId=327&activityTag=4' variant='primary'>
				Join ByBit Airdrop
			</Button>
		</CampaignBybitContainer>
	);
};
export default CampaignBybit;
