import requestHandler from 'shared/utils/request-handler';
import { IGetChristmasResponse, IPostGetRewardResponse } from './christmas.dto';

const BASE_URL = '/api/v3/Christmas';

export const GETChristmas = async () => {
	return requestHandler.call<IGetChristmasResponse>({ url: `${BASE_URL}/Get`, method: 'get' }).then(res => res.data);
};

export const POSTGetReward = async () => {
	return requestHandler
		.call<IPostGetRewardResponse>({
			url: `${BASE_URL}/GetReward`,
			method: 'post',
		})
		.then(res => res.data);
};
