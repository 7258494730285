import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CardsContainer = styled.section(
	() => css`
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
		gap: 8px;
		position: relative;
		top: 50px;
		@media (max-width: 350px) {
			top: 30px;
		}
	`,
);
