import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const AcademyContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		padding: 24px 24px 160px;
		background: linear-gradient(180deg, #2372a2 10%, rgba(127, 178, 211, 1) 50%, rgba(34, 113, 161, 1) 100%);
		h1 {
			font-size: 22px;
			font-weight: 400;
			line-height: 15px;
			text-align: center;
			margin-bottom: 12px;
		}
		p {
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			text-align: center;
			margin-bottom: 24px;
		}
		.completed {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			margin: auto;
			width: 100%;
			height: 100%;
			border-radius: 21px;
			background: #21548ab0;
			z-index: 2;
			display: flex;
			align-items: center;
			justify-content: center;
			.icon {
				width: 42px;
			}
		}
		.border {
			margin-bottom: 10px;
			padding: 1.5px;
			border-radius: 21px;
			background: linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
			.bg {
				background: linear-gradient(
					160.54deg,
					#3993cb 0.08%,
					#3993cb 13%,
					#3993cb 19.32%,
					#69bef3 32.87%,
					#3993cb 45.47%,
					#3993cb 60.21%
				);
				border-radius: 19.5px;
				padding: 20px 17px;
				h3 {
					padding-left: 8px;
					margin-bottom: 10px;
					font-size: 18px;
					line-height: 15px;
				}
				.card_container {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					gap: 8px;
					.card_border {
						padding: 1.5px;
						border-radius: 21px;
						background: linear-gradient(225.39deg, rgba(255, 255, 255, 0.6) 35.3%, rgba(35, 135, 197, 0.27) 67.25%);
						box-shadow: 0px 4px 4px 0px #115f8f;
						position: relative;

						.card {
							background: #0375bb;
							border-radius: 19.5px;
							padding: 16px 17.5px 8px;
							height: 100%;
							display: grid;
							grid-template-columns: 1fr;
							grid-template-rows: 68px auto 30px;
							align-items: center;
							gap: 10px;
							.img_top {
								border-radius: 8px;
								width: 100%;
							}
							.title {
								font-size: 13px;
								font-weight: 600;
								line-height: 17px;
								text-align: center;
							}
							.border_top {
								padding: 1.5px;
								border-radius: 16px;
								background: linear-gradient(180deg, rgba(255, 255, 255, 0.45) -14.29%, rgba(153, 153, 153, 0) 50%);
								width: 100%;
								position: relative;
								bottom: 0;
								.reward {
									background: #0375bb;
									display: flex;
									justify-content: center;
									align-items: center;
									border-radius: 15.5px;
									gap: 4px;
									font-size: 14px;
									font-weight: 600;
									line-height: 17.33px;
									padding-top: 6px;
									p {
										margin: 3px 0 0;
									}
									img {
										width: 19px;
										height: 19px;
									}
								}
							}
						}
					}
				}
			}
		}
	`,
);
