import { NavLink, useLocation } from 'react-router-dom';
import { Earn, Prana, WAllet, Shop } from './img';
import { MenuContainer } from './menu.style';
import { useAppSelector } from 'shared/hooks/redux-helper';
// import { useState } from 'react';
// import MaintenanceModal from 'pages/home/components/maintenance-modal';

const Menu = () => {
	// const [modalStatus, setModalStatus] = useState<{
	// 	status: boolean;
	// }>({
	// 	status: false,
	// });
	const location = useLocation();
	const remainTasks = useAppSelector(state => state.profile.tasks.pendingTasksCount);
	const isActive = (path: string) => location.pathname === path;

	const renderIcon = (path: string, ActiveIcon: React.FC, InactiveIcon: React.FC) => {
		return isActive(path) ? <ActiveIcon /> : <InactiveIcon />;
	};

	const onNavItemClick = () => {
		window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
	};

	return (
		<MenuContainer pathname={location.pathname}>
			<div className='inner_nav_container'>
				<NavLink
					className={'left'}
					to={'/wallet'}
					onClick={() => {
						!isActive('/wallet') && onNavItemClick();
					}}
				>
					{isActive('/wallet') && <div className='active_item left' />}

					{renderIcon('/wallet', WAllet, WAllet)}
					<p>Airdrop</p>
				</NavLink>
				{/* <NavLink
					to={'/friends'}
					onClick={() => {
						!isActive('/friends') && onNavItemClick();
					}}
				>
					{isActive('/friends') && <div className='active_item ' />}

					{renderIcon('/friends', FriendsActive, FriendsActive)}
					<p>Friends</p>
				</NavLink> */}

				<NavLink
					to={'/avatar-shop'}
					onClick={() => {
						!isActive('/avatar-shop') && onNavItemClick();
					}}
				>
					{isActive('/avatar-shop') && <div className='active_item' />}

					{renderIcon('/avatar-shop ', Shop, Shop)}

					<p>Shop</p>
				</NavLink>

				<NavLink
					to='/'
					className='center'
					onClick={() => {
						!isActive('/') && onNavItemClick();
					}}
				>
					<img src={isActive('/') ? `/img/gif-home.gif` : '/img/eye.webp'} alt='' width={'53px'} height={'53px'} />
				</NavLink>
				<NavLink
					to='/prana/shop'
					onClick={() => {
						!isActive('/prana/shop') && onNavItemClick();
					}}
				>
					{isActive('/prana/shop') && <div className='active_item ' />}

					{renderIcon('/prana/shop', Prana, Prana)}
					<p>Prana</p>
				</NavLink>
				<NavLink
					className={'right'}
					to={'/earn'}
					onClick={() => {
						!isActive('/earn') && onNavItemClick();
					}}
				>
					{isActive('/earn') && <div className='active_item right' />}

					{renderIcon('/earn', Earn, Earn)}
					{remainTasks > 0 && <div className='remain_tasks'>{remainTasks}</div>}

					<p>Earn</p>
				</NavLink>
				{/* <NavLink
					className='last'
					to={'/wallet'}
					onClick={() => {
						!isActive('/wallet') && onNavItemClick();
					}}
				>
					{isActive('/wallet') && <div className='active_item ' />}

					{renderIcon('/wallet', WAllet, WAllet)}
					<p>Wallet</p>
				</NavLink> */}
			</div>
			{/* {modalStatus.status && (
				<MaintenanceModal setStatus={() => setModalStatus({ status: false })} status={modalStatus.status} key={'earn'} />
			)} */}
		</MenuContainer>
	);
};

export default Menu;
