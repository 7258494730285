import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ChristmasContainer = styled.div(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		position: relative;
		padding: 16px 16px 180px;

		.bg {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			object-fit: cover;
			z-index: 1;
		}
		h1 {
			font-size: 24px;
			font-weight: 600;
			line-height: 29.52px;
			text-align: center;
			position: relative;
			z-index: 2;
		}
		.gift_container {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 20px;
			margin: 20px 0 90px;
			position: relative;
			z-index: 2;
			top: 35vh;
			.gift {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				.head {
					width: 100%;
					object-fit: contain;
					top: -8px;
					position: absolute;
					left: 0;
					right: 0;
					margin: auto;
					z-index: 2;
					transition: transform 1.5s ease-in-out;
				}
				.head_move {
					transform: translateY(-55px);
				}
				.base {
					width: 120%;
					object-fit: contain;
					position: relative;
				}
			}
		}
	`,
);
