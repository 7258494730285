import { ListContainer } from '../css';

const List = () => {
	const data = [
		{
			icon: '/img/wallet-v2/bag.webp',
			title: 'Balance Overview',
			value: '???',
		},
		{
			icon: '/img/wallet-v2/profit.webp',
			title: 'Profit Earned',
			value: '???',
		},
		{
			icon: '/img/friends/f-plus.webp',
			title: 'Friends Invited',
			value: '???',
		},
		{
			icon: '/img/wallet-v2/day.webp',
			title: 'Active Days',
			value: '???',
		},
		{
			icon: '/img/wallet-v2/cal.webp',
			title: 'Month of Joining',
			value: '???',
		},
		{
			icon: '/img/wallet-v2/list.webp ',
			title: ' Tasks Completed',
			value: '???',
		},
	];
	return (
		<ListContainer>
			<div className='list'>
				{data.map(item => (
					<div className='list_item'>
						<img className='icon' src={item.icon} alt='' />
						<p className='list_title'>{item.title} </p>
						<div className='coin'>{item.value}</div>
					</div>
				))}
			</div>
		</ListContainer>
	);
};

export default List;
