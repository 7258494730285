import { FC } from 'react';
import Modal from 'shared/components/modal';
import { InfoModalContainer } from '../css';
import useCloseModal from 'shared/hooks/use-close-modal';
import { useNavigate } from 'react-router-dom';
import { IGetClaimPractice } from 'api/v1/practice';
import ButtonNew from 'shared/components/button_new';

interface IInfoMoalProps {
	status: boolean;
	setStatus: (status: boolean) => void;
	congratsContent: IGetClaimPractice['modal'];
}

const InfoModal: FC<IInfoMoalProps> = ({ status, setStatus, congratsContent }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const navigate = useNavigate();
	return (
		<Modal isBottomSheet status={_status} onClose={onClose} title={''}>
			<InfoModalContainer>
				<img src={congratsContent.iconUrl} alt='' className='calender' />
				<div className='content'>
					<h3>Congratulation 🎉</h3>
					<p>{congratsContent.text}</p>

					<ButtonNew
						className='btn'
						variant='thirty'
						onClick={() => {
							navigate('/prana/shop');
							onClose();
						}}
					>
						{congratsContent.buttonText}
					</ButtonNew>
				</div>
			</InfoModalContainer>
		</Modal>
	);
};

export default InfoModal;
