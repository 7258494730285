import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface PineyeContainerProps {
	level: number;
}

export const PineyeContainer = styled.section<PineyeContainerProps>(
	({ level }) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: -1px;
		position: relative;
		/* padding: 20px 0 16px; */
		user-select: none;
		touch-action: none !important;
		/* background-image: url('/img/pineye/${level}back.webp'); */
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		-webkit-transform: translateZ(0);
		-webkit-backface-visibility: hidden;
		-webkit-perspective: 1000;
		z-index: 2;
		img {
			width: 100%;
			position: relative;
			z-index: 4;
			top: -12vh;
		}

		.animated_text {
			will-change: transform;
			z-index: 5;
			position: absolute;
			/* transform: translate(-50%, -50%); */
			animation: MOVE_UP 1s ease;
			animation-delay: -2ms;
			font-size: 34px;
			color: white;
			pointer-events: none;
			font-weight: 800;
		}

		@keyframes MOVE_UP {
			0% {
				opacity: 1;
				transform: translate3d(0, 0, 0);
			}
			100% {
				opacity: 0;
				transform: translate3d(0, -150px, 0);
			}
		}
		@media (max-height: 1000px) {
			height: 360px;
			img {
				top: -2vh;
				width: 108%;
			}
		}
		@media (max-height: 900px) {
			height: 360px;
			img {
				top: -6vh;
				width: 110%;
			}
		}
		@media (max-height: 900px) and (max-width: 350px) {
			height: 360px;
			img {
				top: -1vh;
				width: 110%;
			}
		}
		@media (max-height: 750px) {
			height: 360px;
			img {
				top: -7vh;
				width: 80%;
			}
		}
		@media (max-height: 688px) {
			height: 340px;
			img {
				margin-bottom: 60px;
			}
		}
		@media (max-height: 650px) {
			height: 320px;
			img {
				margin-bottom: 90px;
			}
		}
		@media (max-height: 620px) {
			img {
				width: 95%;
			}
		}
		@media (max-height: 570px) {
			img {
				width: 80%;
				top: -19vh;
			}
		}
		@media (max-height: 555px) {
			/* margin-top: -13px; */
			padding: 10px 0 8px;

			img {
				width: 80%;
			}
		}
	`,
);
