import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const VipContainer = styled.div(
	() => css`
		background: #002655;
		padding-bottom: 120px;
		overflow-x: hidden;

		position: relative;
		.head {
			width: 220px;
			height: 220px;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
			z-index: 1;
		}
		.light {
			position: absolute;
			top: 30px;
			left: -80px;
			right: 0;
			width: 600px;
			margin: auto;
			z-index: 0;
			height: 260px;
			border-radius: 90px;
			background: radial-gradient(43.04% 43.04% at 50% 50%, #07549e 0%, rgba(1, 39, 86, 0) 100%);
		}
		.connected_content {
			padding-top: 250px;
			color: #fff;
			display: flex;
			flex-direction: column;
			gap: 16px;
			align-items: center;
			h1 {
				position: relative;
				text-align: center;
				font-size: 24px;
				font-weight: 600;
				line-height: 30px;
			}
			h2 {
				font-size: 20px;
				font-weight: 600;
				line-height: 25px;
				text-align: center;
			}
			.des {
				font-size: 14px;
				font-weight: 500;
				line-height: 20px;
				text-align: center;
				padding: 0 24px;
			}
			.visit {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				text-align: center;
				padding: 0 24px;
				margin-top: 16px;
			}
			.text {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				text-align: center;
				span {
					line-height: 35px;
					font-weight: 500;
				}
			}
			.des {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;
				span {
					font-size: 14px;
					font-weight: 400;
					line-height: 35px;
				}
				img {
					width: 34px;
				}
			}
			.tick {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 14px 24px;
				background-color: #001c40;
				border-radius: 16px;
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				text-align: center;
				margin-top: 50px;
				.icon {
					width: 31px;
				}
			}
			.copy_div {
				width: 92%;
				background: #1068b9;
				border-radius: 16px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: auto;
				left: 0;
				right: 0;
				margin-top: 16px;
				position: relative;
				p {
					text-align: center;
					max-width: 95%;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					padding-inline-start: 8px;
				}
				.copy_icon {
					padding: 16px 22px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #f5c907;
					border-radius: 16px;
					svg {
						color: #000;
					}
				}
			}
		}
		.content {
			padding-top: 250px;
			color: #fff;
			display: flex;
			flex-direction: column;
			gap: 16px;
			align-items: center;
			h1 {
				position: relative;
				text-align: center;
				font-size: 24px;
				font-weight: 600;
				line-height: 30px;
			}
			.text {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				text-align: center;
				span {
					line-height: 35px;
					font-weight: 500;
				}
			}
			.des {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;
				span {
					font-size: 14px;
					font-weight: 400;
					line-height: 35px;
				}
				img {
					width: 34px;
				}
			}
			.copy_div {
				width: 92%;
				background: #1068b9;
				border-radius: 16px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: auto;
				left: 0;
				right: 0;
				margin-top: 16px;
				position: relative;
				p {
					text-align: center;
					width: 100%;
				}
				.copy_icon {
					padding: 16px 22px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #f5c907;
					border-radius: 16px;
					svg {
						color: #000;
					}
				}
			}
			.visit {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				text-align: center;
				padding: 0 24px;
				margin-top: 16px;
			}
		}
	`,
);
