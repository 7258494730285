import { GETCampaign, IGetCampaignResponse, ITasks } from 'api/v1/campaign';

import { createCustomApi } from 'store/base.api';
import { AppDispatch } from 'store/types';

const apis = {
	campaign: GETCampaign,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data: data };
};

const GameSliceApi = createCustomApi({
	reducerPath: 'games-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getCampaign: builder.query<IGetCampaignResponse & { waitIfFail?: number; loadingTaskId?: number | null }, void>({
			query: () => ({ path: 'campaign' }),
		}),
	}),
});

export const updateCampaign =
	(data: { isClaimedReward: boolean; tasks: ITasks[]; waitIfFail: number; loadingTaskId?: null | number }) => (dispatch: AppDispatch) => {
		dispatch(
			GameSliceApi.util.updateQueryData('getCampaign', undefined, draft => {
				draft.loadingTaskId = data.loadingTaskId || null;
				draft.waitIfFail = data.waitIfFail || 0;
				draft.isClaimedReward = data.isClaimedReward;
				draft.tasks = data.tasks;
				if (data.tasks.every(task => task.isDone)) {
					draft.isTasksCompleted = true;
				}
			}),
		);
	};
export const { useGetCampaignQuery } = GameSliceApi;

export default GameSliceApi;
