import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CardHistoryContainer = styled.section(
	({ theme }) => css`
		position: relative;
		padding: 16px 16px 80px;
		.background {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			width: 100%;
			height: 100vh;
			min-height: 100vh;
			background-size: cover;
			background-attachment: fixed;
			background-repeat: no-repeat;
		}

		h1 {
			padding-top: 15px;
			font-size: 22px;
			font-weight: 600;
			line-height: 27.24px;
			text-align: center;
			position: relative;
			z-index: 1;
		}
		.top {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
			z-index: 2;
		}
		.header {
			position: absolute;
			z-index: 2;
			display: grid;
			grid-template-columns: 54px auto 54px;
			align-items: center;
			top: 8px;
			gap: 16px;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
			padding: 0 12px;
			.big_icon {
				width: 54px;
			}
			.status {
				width: 100%;
				display: grid;
				grid-template-columns: repeat(5, 35px);
				gap: 6px;
				align-items: center;
				justify-content: space-between;
				.status_item {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					img {
						width: 35px;
					}
					p {
						font-size: 12px;
						font-weight: 500;
						line-height: 15.61px;
						color: ${theme.colors.text.primary};
					}
				}
			}
		}
		.levels {
			position: relative;
			display: flex;
			flex-direction: column;
			gap: 8px;
			padding-bottom: 6px;

			h2 {
				font-size: 15px;
				font-weight: 600;
				line-height: 18.57px;
				text-align: center;
			}
			.divider {
				height: 2px;
				background: #9b6f40;
				margin: 8px 32px;
				text-align: center;
				position: relative;
				z-index: 2;
			}
			.items {
				position: relative;
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				grid-template-rows: 1fr;
				gap: 10px;
				/* padding: 0 16px; */

				.item {
					position: relative;
					width: 100%;

					& .wrong {
						box-shadow: 0px 0px 0px 4px #ff2626;
						-webkit-box-shadow: 0px 0px 0px 4px #ff2626;
						-moz-box-shadow: 0px 0px 0px 4px #ff2626;
					}
					& .correct {
						box-shadow: 0px 0px 0px 4px #0c5015;
						-webkit-box-shadow: 0px 0px 0px 4px #0c5015;
						-moz-box-shadow: 0px 0px 0px 4px #0c5015;
					}
					& .answer_pic {
						position: absolute;
						top: 30%;
						width: 35px;
						left: 0;
						right: 0;
						margin: auto;
					}
					.your_choice {
						position: absolute;
						top: 50%;
						left: 0;
						right: 0;
						margin: auto;
						z-index: 2;
						color: #fff;
						font-size: 12px;
						font-weight: 600;
						line-height: 15px;
					}

					strong {
						position: absolute;
						bottom: 40px;
						left: 0;
						right: 0;
						margin: auto;
						z-index: 2;
						color: #fff;
						text-align: center;
						font-size: 12px;
						line-height: 14.86px;
					}
					p {
						font-size: 14px;
						font-weight: 600;
						line-height: 17px;
						text-align: center;
					}
					.card_img {
						width: 100%;
						position: relative;
					}
					.status {
						position: absolute;
						width: 45px;
						top: 9vh;
						right: 0;
						left: 0;
						margin: auto;
					}
					.passed {
						position: absolute;
						width: 70px;
						top: 8vh;
						right: 0;
						left: 0;
						margin: auto;
					}
				}
			}
		}
		.start_btn {
			width: 92% !important;
			position: fixed;
			bottom: 105px;
			z-index: 2;
			left: 0;
			right: 0;
			margin: auto;
			background-image: url('/img/game-card/btn-larg.webp') !important;
			background-size: cover !important;
			background-position: center !important;
			box-shadow: unset;
			border: unset;
		}
	`,
);
