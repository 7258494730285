import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const WalletContainer = styled.div(
	() => css`
		position: relative;
		padding-bottom: 120px;
		background-color: #002554;
		header {
			position: relative;

			.header_bg {
				position: absolute;
				width: 100%;
				top: 0;
				right: 0;
				z-index: 1;
			}
			.header_hero {
				position: absolute;
				top: 30px;
				z-index: 3;
				width: 60vw;
			}
			.header_overlay {
				position: absolute;
				top: 35vw;
				width: 100%;
				height: 30vw;
				z-index: 2;
				background: linear-gradient(to bottom, transparent 0%, #002554 35%, #002554 100%);
			}
		}

		.content {
			position: relative;
			z-index: 2;
			padding-top: calc(65vw - 120px);

			@media (min-width: 390px) {
				padding-top: calc(60vw - 140px);
			}

			.timer_title {
				font-size: 13.5px;
				font-weight: 400;
				line-height: 16px;
				text-align: center;
				color: #fff;
				margin-bottom: 8px;
			}
			.content_timer {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				padding: 21px;
			}
		}

		/* .light_circle {
			background: radial-gradient(43.04% 43.04% at 50% 50%, #07549e 0%, rgba(1, 39, 86, 0) 100%);
			position: fixed;
			width: 646px;
			height: 335px;
			left: -68px;
			top: 50vh;
		} */
	`,
);
