import { GETAcademies, IGetAcademies } from 'api/v3/academies';
import { createCustomApi } from 'store/base.api';
import { AppDispatch } from 'store/types';

const apis = {
	academies: GETAcademies,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data: data };
};

const AcademiesSliceApi = createCustomApi({
	reducerPath: 'academies-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getAcademies: builder.query<IGetAcademies[], void>({
			query: () => ({ path: 'academies' }),
		}),
	}),
});

export const updateAcademies =
	(data: {
		id: number;
		isClaimed: boolean;
		answer: {
			questionItemId: number;
		};
		userAnswer: {
			questionItemId: number;
		};
	}) =>
	(dispatch: AppDispatch) => {
		dispatch(
			AcademiesSliceApi.util.updateQueryData('getAcademies', undefined, draft => {
				draft.forEach(item => {
					if (item.id === data.id) {
						item.isClaimed = data.isClaimed;
						item.answer = data.answer;
						item.userAnswer = data.userAnswer;
					}
				});
			}),
		);
	};

export const { useGetAcademiesQuery } = AcademiesSliceApi;
export default AcademiesSliceApi;
