import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const MapContainer = styled.div(
	() => css`
		height: 100vh;
		position: relative;
		.bg {
			position: fixed;
			top: 0;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center;
		}
		.home {
			position: absolute;
			width: 95px;
			height: 90px;
			inset-inline-start: 10px;
			bottom: 35px;
			z-index: 10;
		}
	`,
);
