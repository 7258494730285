import { ISocialResponse } from 'api/v1/social';
import { FC, useState } from 'react';
import { ArrowRightOutline, TickBold } from 'shared/components/icons';
import Loader from 'shared/components/loader';
import numberUtils from 'shared/utils/number';
import { SpecialContainer } from '../css';
import SocialModal from './social-modal';

interface ISocialProps {
	socialData: ISocialResponse[] | null;
	setSocialData: React.Dispatch<React.SetStateAction<ISocialResponse[] | null>>;
}

const Partner: FC<ISocialProps> = ({ socialData }) => {
	const [socialModalStatus, setSocialModalStatus] = useState<{
		status: boolean;
		id: number;
		reward: number;
		title: string;
		link: string;
		isClaimed: boolean;
		modal: ISocialResponse['modal'];
	}>({
		id: 0,
		link: '',
		reward: 0,
		title: '',
		status: false,
		isClaimed: false,
		modal: {
			buttonTitle: '',
			modalIconUrl: '',
			modalText: '',
		},
	});

	const partners = socialData?.filter(item => item.category === 'Special' && item.subCategory === 'Partners' && !item.isClaimed);

	return (
		<SpecialContainer>
			{socialData ? (
				<>
					{partners && partners?.length > 0 && (
						<div className='border'>
							<div className='other_tasks'>
								<div className='special_title'> Partners </div>
								{partners.map((item, index) => (
									<div
										className='special_card'
										key={`social_item_${index}`}
										onClick={() => {
											setSocialModalStatus({
												id: item.id,
												link: item.link,
												reward: item.score,
												title: item.title,
												status: true,
												isClaimed: item.isClaimed,
												modal: item.modal,
											});
										}}
									>
										<div className='img_container'>
											<img src={item.iconUrl} width={44} height={44} />
										</div>
										<div className='title'>{item.title}</div>
										<div className='value'>
											<img src='/img/avatar-shop/diamond.webp' alt='d' />

											{numberUtils.formatPriceWithDecimals(item.isClaimed ? item.claimedScore : item.score)}
										</div>
										{!item.isClaimed ? <ArrowRightOutline /> : <TickBold className='green' />}
									</div>
								))}
							</div>
						</div>
					)}
					<div className='border'>
						<div className='other_tasks'>
							<div className='special_title'> Compeleted </div>
							{socialData
								.filter(item => item.isClaimed && item.category === 'Special' && item.subCategory === 'Partners')
								.map((item, index) => (
									<div
										className='special_card'
										key={`social_item_${index}`}
										onClick={() => {
											setSocialModalStatus({
												id: item.id,
												link: item.link,
												reward: item.score,
												title: item.title,
												status: true,
												isClaimed: item.isClaimed,
												modal: item.modal,
											});
										}}
									>
										<div className='img_container'>
											<img src={item.iconUrl} width={44} height={44} />
										</div>
										<div className='title'>{item.title}</div>
										<div className='value'>
											<img src='/img/avatar-shop/diamond.webp' alt='d' />

											{numberUtils.formatPriceWithDecimals(item.isClaimed ? item.claimedScore : item.score)}
										</div>
										{!item.isClaimed ? <ArrowRightOutline /> : <TickBold className='green' />}
									</div>
								))}
						</div>
					</div>
				</>
			) : (
				<Loader />
			)}

			{socialModalStatus.status && (
				<SocialModal
					id={socialModalStatus.id}
					link={socialModalStatus.link}
					reward={socialModalStatus.reward}
					status={socialModalStatus.status}
					isClaimed={socialModalStatus.isClaimed}
					setStatus={status => {
						setSocialModalStatus({ ...socialModalStatus, status: status });
					}}
					title={socialModalStatus.title}
					modal={socialModalStatus.modal}
				/>
			)}
		</SpecialContainer>
	);
};

export default Partner;
