import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks/redux-helper';
import { OverviewContainer } from '../css';
// import { GetExchange } from 'api/v1/exchange';
// import DotLoader from 'shared/components/dot-loader';

import InfoModal from 'pages/games/components/info-modal';
import numberUtils from 'shared/utils/number';
import CountDown from './count-down';

const Overview = () => {
	const profile = useAppSelector(state => state.profile);

	const [statusModal, setStatusModal] = useState(false);
	const [type, setType] = useState('');

	// const [exchangeName, setExchangeName] = useState<string | null>(null);

	// const getExchangeImage = (exchangeName: string) => {
	// 	switch (exchangeName.toLowerCase()) {
	// 		case 'bybit':
	// 			return <img src='/img/pineye/bybit.webp' className='pic ' alt=' ' />;
	// 		case 'binance':
	// 			return <img src='/img/pineye/binance.webp' className='pic ' alt=' ' />;
	// 		case 'kucoin':
	// 			return <img src='/img/pineye/kucoin.webp' className='pic ' alt=' ' />;
	// 		case 'okx':
	// 			return <img src='/img/pineye/okx.webp' className='pic ' alt=' ' />;
	// 		default:
	// 			return null;
	// 	}
	// };

	/*const openModal = () => {
		if (profit < 700) {
			setMinerModalStatus(true);
		} else {
			navigate('/games/miner');
		}
	};*/
	// const currentLevel = levels?.find(item => item.level === profile.level);

	return (
		<OverviewContainer>
			<Link to={'/leagues'} className='level'>
				<div className='inner'>Level {profile.level.no}</div>
				<div className='progress_container '>
					<div className='border'>
						{profile.level.no !== 0 && (
							<div
								className='filled'
								style={{
									width:
										profile.balance > profile.level.toBalance
											? '100%'
											: `${(profile.balance * 100) / profile.level.toBalance}%`,
								}}
							></div>
						)}
					</div>
				</div>
				<p className='level_p'>
					{profile?.energy?.currentEnergy}/
					{profile?.energy?.maxEnergy && +profile.energy.maxEnergy > 2000
						? numberUtils.formatNumberWithSuffix(profile.energy.maxEnergy, 1)
						: profile?.energy?.maxEnergy}
				</p>
				<div className='divider-h' />
			</Link>
			<div className='dividerr' />

			<div className='level  '>
				<p className='listing'>TGE Listing</p>
				<CountDown date={1737979200000} />
				{/* <div className='card_item'>
					<div className='pic  '>
						 {exchangeName === null || exchangeName === '' ? ( 
						<img src='/img/new-home/bybit.webp' alt='pin' width={29} height={29} />
						 ) : (
							getExchangeImage(exchangeName)
						)} 
					</div>
					<div className='text_container'>
						<div className='value'>
							ByBit
							<br />
							<span>Airdrop</span>
							{exchangeName === null ? <DotLoader /> : exchangeName === '' ? 'Choose' : exchangeName} 
						</div>
					</div>
				</div> */}
			</div>
			<div className='dividerr' />
			{/* </Link> */}
			<Link to={'/wallet'} className='level'>
				<div className='card_item'>
					<div className='pic  '>
						<img src='/img/wallet.webp' alt='pin' width={29} height={29} />
					</div>
					<div className='text_container'>
						<div className='value'>
							Wallet
							<br />
							<span>Connect</span>
						</div>
					</div>
				</div>
				<div className='divider-h' />
			</Link>
			{statusModal && type.length > 0 && (
				<InfoModal setStatus={setStatusModal} setType={() => setType('')} type={type} status={statusModal} />
			)}
		</OverviewContainer>
	);
};
export default Overview;
