import { FC } from 'react';
import { PineyeContainer } from '../css/pineye.style';

interface IProps {
	img: string;
	pet: string;
}
const Pineye: FC<IProps> = ({ img, pet }) => {
	return (
		<PineyeContainer>
			<img src={img} draggable={false} className={`armor ${pet?.length > 0 ? 'whit_pet' : ''}`} />
			<img src={pet} draggable={false} className='pet' />
		</PineyeContainer>
	);
};

export default Pineye;
