import { MapContainer } from './css';
// import { LandscapePrompt } from './components';
import { useNavigate } from 'react-router-dom';

const MapPage = () => {
	const navigate = useNavigate();
	return (
		<MapContainer>
			{/* The rotate-prompt overlay will automatically show if in portrait */}
			{/* <LandscapePrompt /> */}

			<img src='/img/map/mapp.webp' alt='' className='bg' />
			<div className='home' onClick={() => navigate('/')}>
				<img src='/img/map/home.webp' alt='' className='home' />
			</div>
		</MapContainer>
	);
};

export default MapPage;
