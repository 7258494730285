import { CSSProperties, FC } from 'react';
import { TimerContainer } from '../css';
import { useCountDown } from 'shared/hooks/use-count-down';

const CountDown: FC<{ date: number }> = ({ date }) => {
	const { days, hours, minutes } = useCountDown(date - new Date().getTime());

	return (
		<TimerContainer>
			<div className='timer_scope timer_scope_1' style={{ '--index': 1 } as CSSProperties}>
				<div className='black'>
					<div className='time'>{days.toString()[0]}</div>
					<div className='time'>{days.toString()[1]}</div>
				</div>
			</div>
			<div className='timer_scope timer_scope_2' style={{ '--index': 2 } as CSSProperties}>
				<div className='black'>
					<div className='time'>{hours.toString()[0]}</div>
					<div className='time'>{hours.toString()[1]}</div>
				</div>
			</div>

			<div className='timer_scope timer_scope_3' style={{ '--index': 3 } as CSSProperties}>
				<div className='black'>
					<div className='time'>{minutes.toString()[0]}</div>
					<div className='time'>{minutes.toString()[1]}</div>
				</div>
			</div>
			<p className='name'>DAYS</p>
			<p className='name'>HRS</p>
			<p className='name'>MINS</p>
		</TimerContainer>
	);
};

export default CountDown;
