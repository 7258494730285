import { useEffect, useState } from 'react';
import { CampaignContainer } from './campaign.style';
import Icon from 'shared/components/icon';
import Button from 'shared/components/button';
import { POSTCheckCampaign, POSTClaimReward, ITasks } from 'api/v2/campaign';
import Loader from 'shared/components/loader';
import numberUtils from 'shared/utils/number';
import { changeBalance } from 'store/reducers/profile';
import toast from 'react-hot-toast';

import { flush, injectGlobal } from '@emotion/css';
import { updateCampaign, useGetCampaignQuery } from 'store/query';
import { useAppDispatch } from 'shared/hooks/redux-helper';

const Campaign = () => {
	const [loadingTaskId, setLoadingTaskId] = useState<number | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { data: dataCampaign } = useGetCampaignQuery();

	const dispatch = useAppDispatch();

	useEffect(() => {
		injectGlobal`
			body,nav{
				/* background: #1d87c9; */
				background: #00001c;
			}
		`;

		return () => flush();
	}, []);

	const handleCampaign = (item: ITasks) => {
		if (item.isDone || !dataCampaign) return;
		if (loadingTaskId !== item.taskId) {
			setLoadingTaskId(item.taskId);
			if (item.details.link.length > 0) {
				window.open(item.details.link, '_blank');
			}

			POSTCheckCampaign(dataCampaign.id, item.taskId).then(res => {
				if (!res.isDone) {
					dispatch(
						updateCampaign({
							waitIfFail: Date.now() + 1000 * 60 * 1,
							isClaimedReward: false,
							loadingTaskId: item.taskId,
							tasks: dataCampaign.tasks.map(task => {
								if (task.taskId === item.taskId) {
									return { ...task, isDone: res.isDone };
								}
								return task;
							}),
						}),
					);

					toast.error('Please try again after 1 minute');
				} else {
					dispatch(
						updateCampaign({
							waitIfFail: Date.now() + 7000,
							isClaimedReward: false,
							loadingTaskId: null,
							tasks: dataCampaign.tasks.map(task => {
								if (task.taskId === item.taskId) {
									return { ...task, isDone: res.isDone };
								}
								return task;
							}),
						}),
					);
					setTimeout(() => {
						setLoadingTaskId(null);
					}, 7000);
				}
			});
		}
	};
	const handleClaimReward = () => {
		setIsLoading(true);
		if (dataCampaign) {
			POSTClaimReward(dataCampaign.id).then(res => {
				dispatch(changeBalance(res));
				toast.success('Reward has been claimed');
				dispatch(updateCampaign({ ...dataCampaign, isClaimedReward: true, waitIfFail: 0, loadingTaskId: null }));
				setIsLoading(false);
			});
		}
	};
	useEffect(() => {
		if (
			dataCampaign?.waitIfFail &&
			dataCampaign?.loadingTaskId &&
			dataCampaign?.loadingTaskId !== null &&
			dataCampaign?.waitIfFail > Date.now()
		) {
			setLoadingTaskId(dataCampaign.loadingTaskId);
		}
		if (dataCampaign?.waitIfFail && dataCampaign?.waitIfFail > Date.now()) {
			setTimeout(() => {
				setLoadingTaskId(null);
			}, dataCampaign?.waitIfFail - Date.now());
		} else if (dataCampaign?.waitIfFail && dataCampaign?.waitIfFail < Date.now()) {
			dispatch(
				updateCampaign({
					...dataCampaign,
					isClaimedReward: false,
					waitIfFail: 0,
					loadingTaskId: null,
					tasks: dataCampaign?.tasks ?? [],
				}),
			);
		}
	}, [dataCampaign?.loadingTaskId]);

	return (
		<CampaignContainer backgroundImgUrl={dataCampaign?.backgroundImgUrl}>
			{!dataCampaign && <Loader />}
			{dataCampaign && (
				<>
					<h1 style={{ marginTop: '110px' }} dangerouslySetInnerHTML={{ __html: dataCampaign.title }}></h1>
					<p className='text'>Don’t miss out, everyone one can Join PinEye Public Sale</p>
					{dataCampaign.tasks.map(item => (
						// {dataCampaign.tasks.map((item, index) => (
						<div
							className='timeline_container'
							key={item.taskId}
							onClick={loadingTaskId ? undefined : () => handleCampaign(item)}
						>
							{/* <div className='number'>{index + 1}</div> */}
							{/* {dataCampaign.tasks.length > index && <div className='line' />} */}
							<div className='item'>
								{item.details.iconUrl ? <img src={item.details.iconUrl} alt='' className='img_item' /> : ''}
								<p>{item.details.title}</p>
								{loadingTaskId === item.taskId ? (
									<Icon name='loader' className='icon_loader' />
								) : item.isDone ? (
									<Icon name='tick' className='icon' />
								) : (
									<Icon name='arrow' className='icon_arrow' />
								)}
							</div>
						</div>
					))}
					<div className='reward'>
						Reward: <img src='/img/dollar.webp' alt='' />+{numberUtils.formatPriceWithDecimals(dataCampaign.reward)}
					</div>
					<Button
						variant='primary'
						className='btn'
						disabled={dataCampaign.isClaimedReward || !dataCampaign.isTasksCompleted}
						onClick={() => handleClaimReward()}
						isLoading={isLoading}
					>
						{dataCampaign.isClaimedReward ? 'Done' : 'Claim Reward'}
					</Button>
				</>
			)}
		</CampaignContainer>
	);
};

export default Campaign;
