import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const AnswerModalContainer = styled.div(
	({ theme }) => css`
		position: relative;
		color: #000;
		padding: 40px 24px;
		height: 270px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		.header_img {
			width: 174px;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			top: -270px;
		}
		h1 {
			font-size: 24px;
			font-weight: 600;
			line-height: 32px;
			text-align: center;
		}
		p {
			font-size: 17px;
			font-weight: 600;
			line-height: 21.05px;
			text-align: center;
		}
		button {
			color: ${theme.colors.text.primary};
			width: 100%;
			height: 54px;
			font-size: 16px;
			font-weight: 600;
			line-height: 20px;
			text-align: center;
			border-radius: 10px;
			background-size: cover !important;
			background-position: center !important;
			background-image: url('/img/game-card/btn-larg.webp') !important;
			box-shadow: unset;
			border: unset;
		}
	`,
);
