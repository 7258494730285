import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const GamesDownContainer = styled.section(
	() => css`
		display: grid;
		grid-template-columns: repeat(3, 120px);
		grid-template-rows: 1fr;
		padding-bottom: 120px;
		z-index: 2;
		position: fixed;
		bottom: 0;
		width: 100%;
		color: #fff;
		justify-content: center;
		align-items: flex-end;
		gap: 0px;
		padding-inline-start: 16px;
		padding-inline-end: 16px;

		._container {
			display: flex;
			justify-content: center;
			position: relative;
			img {
				width: inherit;
				height: 125px;
			}
		}
		.map {
			top: -10px;
		}
		.sides {
			width: 120%;
		}
		.right {
			inset-inline-end: 19%;
		}
		@media (max-width: 370px) {
			padding-bottom: 100px;
			grid-template-columns: repeat(3, 99px);
			._container {
				img {
					height: 100px;
				}
			}
		}
		@media (max-height: 882px) and (max-width: 350px) {
			/* height: 50px; */
		}
		@media (max-width: 320px) {
			padding-bottom: 100px;
			grid-template-columns: repeat(3, 95px);
			._container {
				img {
					height: 95px;
				}
			}
		}
	`,
);
