import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CharacterContainer = styled.section(
	() => css`
		position: relative;
		.char {
			width: 300px;
			position: absolute;
			top: -230px;
			inset-inline-end: 15%;
			z-index: 2;
		}
		.pet {
			width: 300px;
			position: absolute;
			top: -250px;
			inset-inline-end: 5%;
			z-index: 1;
		}
		@media (max-width: 350px) {
			.char {
				width: 250px;
				top: -190px;
				inset-inline-end: 15%;
			}
			.pet {
				width: 220px;
				top: -180px;
				inset-inline-end: 10%;
			}
		}
	`,
);
