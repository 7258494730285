//@ts-ignore
import io, { Socket } from 'socket.io-client';

import { setConnection, setNotification, setBalance, ISocketState } from 'store/reducers/socket';
import { AppDispatch } from 'store/types';

const SOCKET_URL = 'wss://wss.pineye.net';

let socket: Socket | null = null;

export const initializeSocket = (userId: string, dispatch: AppDispatch) => {
	if (!socket) {
		socket = io(SOCKET_URL, {
			transports: ['websocket'],
			reconnection: true,
			// reconnectionAttempts: 5,
			reconnectionDelay: 1000,
		});

		socket.on('connect', () => {
			dispatch(setConnection(true));
			socket?.emit('onconnect', { text: userId });
		});

		socket.on('disconnect', () => {
			socket?.emit('ondisconnect', { text: userId });
			dispatch(setConnection(false));
		});

		socket.on('notification', (data: ISocketState['notification']) => {
			dispatch(setNotification(data));
		});

		socket.on(
			'balance',
			(
				data:
					| {
							success: boolean;
							body: {
								data: {
									ChatId: number;
									Balance: number;
									FactorId: number;
									Value: number;
								}[];
							};
					  }
					| ISocketState['balance'],
			) => {
				try {
					// Type guard for array data
					if (data.body && 'data' in data.body && Array.isArray(data.body.data)) {
						dispatch(setBalance({ body: data.body.data[0] }));
					}
					// Handle ISocketState['balance'] type
					else if ('Balance' in data.body) {
						dispatch(setBalance({ body: data.body }));
					} else {
						console.error('Invalid balance data structure:', data);
					}
				} catch (error) {
					console.error('Error handling balance event:', error);
				}
			},
		);
		socket.on('ping', () => {
			socket.emit('echo', { text: userId });
		});
	}
	return socket;
};

export const disconnectSocket = () => {
	if (socket) {
		socket.disconnect();
		socket = null;
	}
};
