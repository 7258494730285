import { GETAllPractice, GETPranaMarketplace, IGetAllPractice, IGetPranaMarketplace } from 'api/v1/practice';
import { createCustomApi } from 'store/base.api';

const apis = {
	'market-place': GETPranaMarketplace,
	'all-practice': GETAllPractice,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data: data };
};

const PranaSliceApi = createCustomApi({
	reducerPath: 'prana-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getMarketPlace: builder.query<IGetPranaMarketplace, void>({
			query: () => ({ path: 'market-place' }),
		}),
		getAllPractice: builder.query<IGetAllPractice, void>({
			query: () => ({ path: 'all-practice' }),
		}),
	}),
});

export const { useGetMarketPlaceQuery, useGetAllPracticeQuery } = PranaSliceApi;

export default PranaSliceApi;
