import { FC } from 'react';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import { GameCardModalContainer } from '../css';
import Button from 'shared/components/button';
import { IProfileResponse } from 'api/v2/profile';
import numberUtils from 'shared/utils/number';
import { useNavigate } from 'react-router-dom';

const GameCardModal: FC<{
	status: boolean;
	setStatus: (status: boolean) => void;
	content: IProfileResponse['notifs'][0]['metatData'];
}> = ({ status, setStatus, content }) => {
	const navigate = useNavigate();
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const meta = JSON.parse(content);

	const onCloseClickHandler = () => {
		onClose();
	};

	const handleChoose = () => {
		onClose();
		navigate('/card-game');
	};
	return (
		<Modal isBottomSheet status={_status} onClose={onCloseClickHandler} title='' backgroundImage='/img/game-card/modal-bg.webp'>
			<GameCardModalContainer>
				<img src={meta?.metadata.choice?.imageUrl} alt='' className='header_img' />
				<img
					src={meta?.metadata.isCorrect ? '/img/game-card/true.webp' : '/img/game-card/false.webp'}
					alt=''
					className='status_img'
				/>
				{meta?.metadata.isCorrect && (
					<p className='reward_container black'>
						Reward:
						<img src='/img/dollar.webp' alt='' className='dollar_img' />+
						{numberUtils.formatPriceWithDecimals(meta?.metadata.reward)}
					</p>
				)}

				<h1 className='black'>{meta?.metadata.isCorrect ? 'Well Done!' : 'Oops!'}</h1>
				<p className='black'>
					{meta?.metadata.isCorrect
						? 'Congratulations! You selected the correct card. Prepare for the next challenge and keep up the great work!'
						: 'Unfortunately, that was the wrong card. Don’t worry—try again with the next challenge!'}
				</p>
				<Button variant='primary' className='btn' onClick={handleChoose}>
					Next Quest
				</Button>
			</GameCardModalContainer>
		</Modal>
	);
};

export default GameCardModal;
