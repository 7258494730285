import { FC, useState } from 'react';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import { AnswerModalContainer } from '../css/answer-modal.style';
import { IGetCardsResponse, POSTSendAnswer } from 'api/v4/card-game';
import Button from 'shared/components/button';
import toast from 'react-hot-toast';
import { useAppDispatch } from 'shared/hooks/redux-helper';
import { updateGameCard } from 'store/query';

const AnswerModal: FC<{
	activeCard: IGetCardsResponse['choice1' | 'choice2' | 'choice3'];
	status: boolean;
	index: number;
	setStatus: (status: boolean) => void;
}> = ({ index, activeCard, status, setStatus }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const [loading, setLoading] = useState(false);
	const dispatch = useAppDispatch();

	const onCloseClickHandler = () => {
		onClose();
	};

	const handleChoose = () => {
		setLoading(true);
		POSTSendAnswer(index, activeCard.no)
			.then(() => {
				toast.success('Card Selected Successfully.');
				dispatch(updateGameCard({ index, userAnswerNo: activeCard.no }));
				onClose();
			})
			.catch(err => {
				toast.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<Modal isBottomSheet status={_status} onClose={onCloseClickHandler} title='' backgroundImage='/img/game-card/modal-bg.webp'>
			<AnswerModalContainer>
				<img src={activeCard.imageUrl} alt='' className='header_img' />
				<h1>{activeCard.title}</h1>
				<p>
					Once the timer finishes, you'll see the result.<p>Are you sure you want to select this card?</p>
				</p>
				<Button variant='primary' isLoading={loading} onClick={handleChoose}>
					Continue
				</Button>
			</AnswerModalContainer>
		</Modal>
	);
};

export default AnswerModal;
