import { FC, useState } from 'react';
import { CardsContainer } from '../css';
import BuyModal from './buy-modal';
import Card from './single-card';
import { IGetPranaMarketplace } from 'api/v1/prana-game';
interface ICardsProps {
	data: IGetPranaMarketplace['categories'];
	selectedTab: string;
}

const Cards: FC<ICardsProps> = ({ data }) => {
	const [buyModalStatus, setBuyModalStatus] = useState<{ status: boolean }>({ status: false });

	const [selectedId, setSelectedId] = useState<{ cardId: number; categoryId: number; level: number } | null>(null);

	return (
		<CardsContainer>
			{data.map((col, index) => (
				<div className='collection_border' key={`${col.id}-${index}-card`}>
					<div className='collection'>
						{/* <p className='box_title'>{col.title.charAt(0).toUpperCase() + col.title.slice(1)}-Part</p> */}
						{col.cards.map((card, i) => (
							<Card
								key={`card-${card.id}-${i}`}
								item={card}
								select={() => {
									setSelectedId({
										cardId: card.id,
										categoryId: col.id,
										level: col.cards.map(c => c.currentLevel)[i],
									});
								}}
								buy={() => setBuyModalStatus({ status: true })}
								lock={card.hasStartDependency}
							/>
						))}
					</div>
				</div>
			))}

			{buyModalStatus.status && (
				<BuyModal status={buyModalStatus.status} setStatus={status => setBuyModalStatus({ status })} data={data} id={selectedId} />
			)}
		</CardsContainer>
	);
};

export default Cards;
