import numberUtils from 'shared/utils/number';
import { AcademyQuestionContainer } from './css';
import Button from 'shared/components/button';
import Loader from 'shared/components/loader';
import { updateAcademies, useGetAcademiesQuery } from 'store/query';
import { POSTClaimAcademy } from 'api/v1/academies';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'shared/hooks/redux-helper';
import { changeBalance } from 'store/reducers/profile';
import Icon from 'shared/components/icon';
import AnswerModal from './modal';

const AcademyQuestion = () => {
	const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
	const [answerModalStatus, setAnswerModalStatus] = useState(false);
	const [answer, setAnswer] = useState(false);

	const dispatch = useAppDispatch();
	const id = window.location.pathname.split('/').pop();
	const { data = [], isLoading, isError } = useGetAcademiesQuery();
	const navigate = useNavigate();

	useEffect(() => {
		window.Telegram.WebApp.BackButton.onClick(() => {
			navigate('/academy');
		});
		return () => {
			window.Telegram.WebApp.BackButton.onClick(() => {
				navigate('/');
			});
		};
	}, []);
	if (isLoading) return <Loader />;
	if (isError) return <div>Error loading data. Please try again later.</div>;
	if (data.length === 0) return <div>No data available.</div>;

	const item = data.find(item => item.id === Number(id));
	const onCloseModal = (state: boolean) => {
		setAnswerModalStatus(state);
	};
	const getClassName = (itemId: number) => {
		if (!item?.isClaimed) return 'item';
		if (item.answer?.questionItemId === itemId) return 'item correct';
		if (item.userAnswer?.questionItemId === itemId) return 'item incorrect';
		return 'item';
	};
	return (
		<AcademyQuestionContainer>
			{item && (
				<>
					<a href={item?.videoLink} target='_blank' rel='noreferrer'>
						<div className='img_container'>
							<img src={item?.icon} alt='' />
							<Icon name='play_btn' className='play_btn' />
						</div>
					</a>
					<div className='title'>{item?.title}</div>
					<div className='description'>{item?.question.title}</div>
					{item.isClaimed ? (
						<div className='items_container'>
							{item.question.items.map(item__ => (
								<div key={item__.id} className={getClassName(item__.id)}>
									<label className='label_'>
										{/* <input type='radio' name='question' value={item.id} disabled={item.isClaimed} /> */}
										{item__.value}
									</label>
								</div>
							))}
						</div>
					) : (
						<div className='items_container'>
							{item?.question.items.map(item_q => (
								<div className='item' key={`${item_q.id}-${item_q.value}`}>
									<input
										type='radio'
										onClick={() => setSelectedAnswer(+item_q.id)}
										id={`${item_q.id}`}
										name='answer'
										value={item_q.value}
										disabled={item.isClaimed}
									/>
									<label htmlFor={`${item_q.id}`}>{item_q.value}</label>
								</div>
							))}
						</div>
					)}
					<div className='reward'>
						<span>Reward:</span> <img src='/img/dollar.webp' alt='' />{' '}
						<p>+{numberUtils.formatPriceWithDecimals(item?.reward)}</p>
					</div>
					<div className='btn_container'>
						<Button
							disabled={item.isClaimed}
							variant='primary'
							onClick={() => {
								if (!id || !selectedAnswer) {
									toast.error('Please select an answer');
									return;
								}

								POSTClaimAcademy(+id, selectedAnswer).then(res => {
									setAnswer(res.isCorrect);
									if (res.isCorrect) {
										setAnswerModalStatus(true);
										dispatch(changeBalance(res));
										dispatch(
											updateAcademies({
												id: +id,
												isClaimed: true,
												answer: { questionItemId: res.answer.questionItemId },
												userAnswer: { questionItemId: selectedAnswer },
											}),
										);
									} else {
										setAnswerModalStatus(true);
										dispatch(
											updateAcademies({
												id: +id,
												isClaimed: true,
												answer: { questionItemId: res.answer.questionItemId },
												userAnswer: { questionItemId: selectedAnswer },
											}),
										);
									}
								});
							}}
						>
							Check Answer
						</Button>
					</div>
				</>
			)}
			{answerModalStatus && item && (
				<AnswerModal answer={answer} coin={item?.reward} setStatus={onCloseModal} status={answerModalStatus} key={'bybit-modal'} />
			)}
		</AcademyQuestionContainer>
	);
};

export default AcademyQuestion;
