import { FC } from 'react';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import { IGetAllPractice } from 'api/v1/practice';
import { PracticeModalContainer } from '../css';
import { useNavigate } from 'react-router-dom';
import ButtonNew from 'shared/components/button_new';

const PracticeModal: FC<{
	status: boolean;
	setStatus: (status: boolean) => void;
	data: IGetAllPractice['practiceList'][0];
}> = ({ status, setStatus, data }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const navigate = useNavigate();

	return (
		<Modal isBottomSheet status={_status} onClose={onClose} title={''}>
			<PracticeModalContainer>
				<img src={data.modalIcon} alt='' className='calender' />
				<div className='content'>
					<h3>{data.modalTitle}</h3>
					<p dangerouslySetInnerHTML={{ __html: data.modalText }} />
				</div>
				<ButtonNew
					variant='thirty'
					className='btn'
					onClick={() => {
						navigate(`/games/prana/practice/${data.id}`);
						onClose();
					}}
				>
					Let’s Practice
				</ButtonNew>
			</PracticeModalContainer>
		</Modal>
	);
};
export default PracticeModal;
