import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux-helper';
import { changeBalance, changeNotifs } from 'store/reducers/profile';
import { useGetChristmasQuery } from 'store/query';
import { useNavigate } from 'react-router-dom';
import { Games, LevelProgress, Overview, Pineye, ProfitModal, GameCardModal, ShowModal, NotifSocketModal } from './components';
import NotifModal from './components/notif-modal';
import { HomeContainer } from './css';
import { IProfileResponse } from 'api/v2/profile';
import { resetSocket } from 'store/reducers/socket';

const Home = () => {
	const [currentNotif, setCurrentNotif] = useState<IProfileResponse['notifs'][0] | null>(null);
	const [profitModalStatus, setProfitModalStatus] = useState(true);
	const [showModalStatus, setShowModalStatus] = useState(false);
	const { data } = useGetChristmasQuery();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [cardGameModalStatus, setCardGameModalStatus] = useState(true);

	const notifs = useAppSelector(state => state.profile.notif);
	const profile = useAppSelector(state => state.profile);
	const notificationFromSocket = useAppSelector(state => state.socket.notification);
	const currentBalance = useAppSelector(state => state.profile.balance);
	const addedBalance = useAppSelector(state => state.socket.balance.body.Value);

	const processNextNotification = () => {
		const newNotif = notifs.filter(i => i.type !== 'PranaProfit' && i.type !== 'CardGameReward');
		if (notifs.length > 0) {
			setCurrentNotif(newNotif[0]);
		} else {
			setCurrentNotif(null);
		}
	};

	useEffect(() => {
		window.Telegram.WebApp.BackButton.isVisible = false;

		return () => {
			window.Telegram.WebApp.BackButton.isVisible = true;
		};
	}, []);

	useEffect(() => {
		window.scroll({ top: 2 });
		const touchCancel = (e: TouchEvent) => {
			e.preventDefault();
		};
		document.addEventListener('touchstart', touchCancel);
		return () => {
			document.removeEventListener('touchstart', touchCancel);
		};
	}, []);

	const onCloseNotif = (state: boolean) => {
		if (!state) {
			const remainingNotifs = notifs.filter(item => item !== currentNotif);
			dispatch(changeNotifs(remainingNotifs));
			processNextNotification();
		}
	};
	const onCloseProfitModal = (state: boolean) => {
		setProfitModalStatus(state);
		const newNotifs = notifs.filter(item => item.type !== 'PranaProfit');
		dispatch(changeNotifs(newNotifs));
	};
	const onCloseCardGameRewardModal = (state: boolean) => {
		setCardGameModalStatus(state);
		const newNotifs = notifs.filter(item => item.type !== 'CardGameReward');
		dispatch(changeNotifs(newNotifs));
	};
	useEffect(() => {
		processNextNotification();
		if (notifs.length === 0) {
			setShowModalStatus(localStorage.getItem('showModal') === 'true' ? true : false);
		}
	}, [notifs]);
	const onCloseShowModal = (state: boolean) => {
		setShowModalStatus(state);
		localStorage.setItem('showModal', 'false');
	};
	const onCloseSocketNotif = (state: boolean) => {
		const newBalance =
			typeof currentBalance === 'number' && typeof addedBalance === 'number' ? currentBalance + addedBalance : currentBalance;

		if (!Number.isNaN(newBalance)) {
			dispatch(changeBalance({ balance: newBalance }));
		}

		if (!state) {
			dispatch(resetSocket());
		}
	};

	return (
		<HomeContainer>
			<Helmet>
				<title>PinEye | home</title>
			</Helmet>

			<img src={`/img/new-home/bg${profile.level.no ?? 1}-1.webp`} alt='' className='base_img' />
			{profile.level.no !== 11 && <img src={`/img/new-home/bg${profile.level.no ?? 1}.webp`} alt='' className='rotate_img' />}
			{profile.level.no === 11 && (
				<video
					loop
					playsInline
					muted={true}
					preload='auto'
					autoPlay={true}
					style={{ display: 'block' }}
					src={`/video/magic-circle.webm`}
					className='video_bg'
				>
					<source src={`/video/magic-circle.webm`} type='video/webm' />
				</video>
			)}
			{data?.canGetReward && (
				<div className='christmas_img_container' onClick={() => navigate('christmas')}>
					<img src='/img/christmas/box.webp' alt='' className='christmas_img' />
				</div>
			)}
			<Overview />
			<Games />
			<Pineye />
			<LevelProgress />

			{!showModalStatus && currentNotif && <NotifModal status={!!currentNotif} setStatus={onCloseNotif} notif={currentNotif} />}
			{!showModalStatus && currentNotif === null && notificationFromSocket.body.Type.length > 0 && !Number.isNaN(currentBalance) && (
				<NotifSocketModal
					status={notificationFromSocket.body.Type.length > 0}
					setStatus={onCloseSocketNotif}
					notif={notificationFromSocket.body}
				/>
			)}

			{!showModalStatus && profitModalStatus && notifs.find(item => item.type === 'PranaProfit') && (
				<ProfitModal
					setStatus={onCloseProfitModal}
					status={profitModalStatus}
					key={'profit-modal'}
					profit={+notifs.find(item => item.type === 'PranaProfit')!.metatData}
				/>
			)}
			{!showModalStatus && cardGameModalStatus && notifs.find(item => item.type === 'CardGameReward') && (
				<GameCardModal
					setStatus={onCloseCardGameRewardModal}
					status={cardGameModalStatus}
					key={'CardGameReward-modal'}
					content={notifs.find(item => item.type === 'CardGameReward')!.metatData}
				/>
			)}
			{showModalStatus && localStorage.getItem('showModal') && <ShowModal status={showModalStatus} setStatus={onCloseShowModal} />}
		</HomeContainer>
	);
};

export default Home;
