import { FC, Fragment } from 'react';
import Button from 'shared/components/button';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import numberUtils from 'shared/utils/number';
import { NotifModalContainer } from '../css';
import { IProfileResponse } from 'api/v2/profile';

const NotifModal: FC<{ status: boolean; setStatus: (status: boolean) => void; notif: IProfileResponse['notifs'][0] }> = ({
	status,
	setStatus,
	notif,
}) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });

	const meta = JSON.parse(notif.metatData);

	return (
		<Modal status={_status} onClose={onClose} title='' disableHeader={true} isBottomSheet>
			<NotifModalContainer>
				{notif.type === 'ReferralReward' ? (
					<img src='/img/referral.webp' className='header_img_welcome' />
				) : notif.type === 'ExtraReferralReward' ? (
					<img src='/img/30.webp' className='header_img_welcome' />
				) : (
					<>
						<img src='img/gift-base.webp' alt='' className='header_img' />
						<img src='img/gift-top.webp' alt='' className='header_img_move' />
					</>
				)}
				<h2>
					<img src='/img/dollar.webp' alt='' className='dollar_img' />+{numberUtils.formatPriceWithDecimals(meta.Reward)}
				</h2>
				{/* <h4>Well done!</h4> */}

				<Fragment key={`section_reward_item_${notif.timeStamp}`}>
					{notif.type === 'WelcomeReward' && (
						<>
							<h4>Welcome!</h4>
							<div className='reward_container'>
								<p>
									Here’s {numberUtils.formatPriceWithDecimals(meta.Reward)} coins to get started. Enjoy your rewards! 🚀{' '}
								</p>
							</div>
						</>
					)}
					{notif.type === 'ReferralReward' && (
						<>
							<p>
								You earned {numberUtils.formatPriceWithDecimals(meta.Reward)} coins from your invitation.
								<br />
								Invite more friends to earn even more rewards!
							</p>
						</>
					)}
					{notif.type === 'ExtraReferralReward' && (
						<>
							<h4>Well done!</h4>
							<p>
								You’ve earned {numberUtils.formatPriceWithDecimals(meta.Reward)} coins from your referrals, 30% of their
								total earnings.
							</p>
						</>
					)}
					{notif.type === 'RetentionReward' && (
						<>
							<p>
								We’ve missed you! Explore exciting new features, personalized updates, and exclusive offers!
								<br />
								Plus, you’ll earn 200,000 coins now!
							</p>
						</>
					)}
				</Fragment>

				<Button variant='primary' onClick={onClose}>
					{notif.type === 'WelcomeReward' ? 'Get Started' : 'Let’s Go'}
				</Button>
			</NotifModalContainer>
		</Modal>
	);
};

export default NotifModal;
