import { AssetType, GETAssets, IGetAsset, AssetStatus } from 'api/v1/assets';
import { createCustomApi } from 'store/base.api';
import { AppDispatch } from 'store/types';

const apis = {
	assets: (type: string) => GETAssets(type),
};

const asyncBaseQuery = async ({ path, type }: { path: keyof typeof apis; type: string }) => {
	const data = await apis[path](type);
	return { data: data };
};

const AssetsSliceApi = createCustomApi({
	reducerPath: 'assets-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getAssets: builder.query<IGetAsset[], string>({
			query: type => ({ path: 'assets', type }),
		}),
	}),
});

export const updateAssets = (data: { type: AssetType; id: number; status: AssetStatus }) => (dispatch: AppDispatch) => {
	dispatch(
		AssetsSliceApi.util.updateQueryData('getAssets', data.type === 1 ? 'pet' : 'armor', draft => {
			const index = draft.findIndex(item => item.id === data.id);
			if (data.status === 2) {
				draft.forEach(item => {
					if (item.status === 2) {
						item.status = 3;
					}
				});
			}
			if (index !== -1) {
				draft[index] = { ...draft[index], status: data.status };
			}
		}),
	);
};

export const { useGetAssetsQuery } = AssetsSliceApi;
export default AssetsSliceApi;
