import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const AvatarShopContainer = styled.section(
	() => css`
		/* background: url(/img/avatar-shop/back.webp); */
		background: url(/img/avatar-shop/bg.webp);

		background-position: top left;
		background-size: cover;
		background-repeat: no-repeat;
		padding: 21px 17px 200px;
		overflow-x: hidden;
		position: relative;
		height: 100vh;
	`,
);
