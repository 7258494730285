import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode, FC } from 'react';

interface MenuContainerProps {
	pathname: string;
	children: ReactNode;
}

export const MenuContainer: FC<MenuContainerProps> = styled.nav<MenuContainerProps>(
	({ theme, pathname }) => css`
		position: fixed;
		bottom: 0px;
		width: 100%;
		z-index: 3;
		user-select: none;
		height: 100px;
		background: ${pathname.includes('card-game') ? 'initial' : 'linear-gradient(360deg, #1b87c9 58.4%, rgba(28, 136, 202, 0) 92.44%)'};
		display: ${pathname.includes('games/prana/practice/') ? 'none' : 'block'};

		.inner_nav_container {
			background: ${pathname.includes('card-game') ? '#D5C7AD' : ` #9dd6ff`};

			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			width: calc(100% - 48px);
			left: 24px;
			right: 20px;
			position: fixed;
			bottom: 24px;
			border-radius: 30px;
			height: 65px;
			align-items: center;
			overflow: hidden;
			.active_item {
				position: absolute;
				top: -6px;
				left: -5px;
				right: 0;
				margin: auto;
				width: 80px;
				height: 80px;
				background: #1b87c9;
				border-radius: 50%;
			}
			a {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 6px;
				color: #284c69;
				font-weight: 400;
				font-size: 11px;
				line-height: 12px;
				padding: 0;

				svg {
					width: 35px;
					height: 35px;
				}
				p {
					font-size: 10px;
					font-weight: 400;
					line-height: 9px;
					text-align: center;
					color: #325771;
				}
				transition: all 0.3s ease-in-out;
				.remain_tasks {
					position: absolute;
					right: 10px;
					top: 3px;
					background-color: #ff0000;
					color: #ffffff;
					border-radius: 50%;
					width: 12px;
					height: 12px;
					padding-top: 1px;
					font-size: 10px;
					font-weight: 600;
					margin-right: 5px;
					margin-top: 2px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				&.active {
					color: ${theme.colors.text.primary};
					fill: ${theme.colors.text.primary};
					background: #1b87c9;
					border-radius: 50%;
					width: 70px;
					height: 70px;
					align-self: flex-start;
					fill: #fff;
					p {
						color: #fff;
						font-size: 12px;
						z-index: 3;
					}
					svg {
						width: 37px;
						height: 37px;
						z-index: 3;
					}
				}

				&.center {
					padding: 0;
					display: flex;
					position: relative;
					justify-content: center;
					align-items: center;
				}
			}
		}
	`,
);
