import { ArrowRightOutline } from 'shared/components/icons';
import { IncomeStatsContainer } from '../css';
import { Link } from 'react-router-dom';
import { FC } from 'react';
import numberUtils from 'shared/utils/number';
import { useAppSelector } from 'shared/hooks/redux-helper';
import { IReferralRewardResponse } from 'api/v1/referral-reward';
import DotLoader from 'shared/components/dot-loader';

const IncomeStats: FC<{ friendsCount?: number; friendsBalance?: number; data: IReferralRewardResponse | undefined }> = ({
	friendsCount,
	friendsBalance,
	data,
}) => {
	const currentLevel = useAppSelector(state => state.profile.level.no);

	const dividerCount = data?.yourFriendsCount && data?.friendsCount ? (data.yourFriendsCount * 7) / data.friendsCount : 0;

	return (
		<IncomeStatsContainer dividerCount={Math.floor(dividerCount)} filledPercentage={Math.floor(dividerCount * 10)}>
			<div className='reward_item'>
				<img src='/img/dollar.webp' width={48} height={48} alt='pineye-coin' />
				<div className='value'>
					<div className='title'>Friends' Rewards (Last 24H)</div>
					{friendsBalance ? numberUtils.formatPriceWithDecimals(friendsBalance) : 0}
				</div>
			</div>
			<div className='friends '>
				<img src='/img/friends/f-plus.webp' width={48} height={48} alt='pineye-coin' />
				<div className='title'>Number of friends:</div>
				<div className='value '>{friendsCount ? numberUtils.formatPriceWithDecimals(friendsCount) : 0}</div>
			</div>
			<Link to={'/reward-history'} className='friends '>
				<img src='/img/friends/gift.webp' alt='' />
				<p>
					<strong>30% Bonus</strong>
					<br />
					Extra coins from friends
				</p>
				<div className='link'>
					<ArrowRightOutline />
				</div>
			</Link>
			{data ? (
				<div className='invite'>
					<div className='img_container'>
						<img src={`/img/pineye/level-${currentLevel ?? 1}.webp`} alt='' className='img_level' />
					</div>
					<div className='text'>
						<p>Invite {`${data?.friendsCount}`} friends</p>
						<div className='progress_bar'>
							<div className='border'>
								<div
									className='filled'
									style={{
										width: `${(data?.yourFriendsCount * 100) / data.friendsCount}%`,
									}}
								></div>
							</div>
						</div>
						<div className='last_line'>
							<div className='reward'>
								<img src='/img/dollar.webp' width={48} height={48} alt='pineye-coin' />+{' '}
								{numberUtils.formatPriceWithDecimals(data.bonus)}
							</div>
							<div className='idk'>
								{numberUtils.formatPriceWithDecimals(data.yourFriendsCount)}/
								{numberUtils.formatPriceWithDecimals(data.friendsCount)}
							</div>
						</div>
					</div>
				</div>
			) : (
				<DotLoader />
			)}
		</IncomeStatsContainer>
	);
};

export default IncomeStats;
