import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const PyramidContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		position: relative;
		background-image: url('/img/campaign/fly.webp');
		background-size: cover;
		min-height: 100vh;
		background-position: center;
		width: 100%;
		height: 100%;
		button {
			position: fixed;
			bottom: 110px;
			left: 0;
			right: 0;
			margin: auto;
			width: 70%;
			background: #f5c907 !important;
			color: #000;
		}
		@media (max-height: 658px) {
			min-height: 130vh;
		}
	`,
);
