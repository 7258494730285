import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const PracticeContainer = styled.div(
	({ theme }) => css`
		position: relative;
		height: 100vh;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 2fr 0.5fr 0.25fr 0.9fr;
		color: ${theme.colors.text.primary};
		.background {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
			height: 100vh;
			z-index: 2;
		}

		.counter_container {
			position: fixed;
			inset: 0;
			z-index: 4;
			display: flex;
			align-content: center;
			justify-content: center;
			background: transparent;
			backdrop-filter: blur(5px);
			.lets_start {
				display: flex;
				width: 100%;
				font-size: 143px;
				font-weight: 900;
				line-height: 50px;
				text-align: center;
				color: #ffffff;
				justify-content: center;
				align-items: center;
				animation: counter 1s infinite;
			}
		}

		.man_container {
			position: relative;
			overflow: hidden;
			display: flex;
			justify-content: center;
			.head {
				display: flex;
				justify-content: space-between;
				z-index: 5;
				width: 100%;
				.close {
					position: relative;
					padding: 30px;
					cursor: pointer;
					width: 45px;
					height: 35px;
					img {
						width: 15px;
					}
				}
				.sound {
					position: relative;
					padding: 30px 30px 0px 0px;
					cursor: pointer;
					width: 45px;
					height: 35px;
					img {
						width: 30px;
					}
				}
			}
			.man {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				width: 80vw;
				z-index: 3;
			}
			.triangle {
				position: absolute;
				bottom: -10%;
				z-index: 1;
				margin: auto;
				left: -20%;
				right: -20%;
				width: 130%;
			}
			.infinite {
				position: absolute;
				top: 0%;
				z-index: 1;
				margin: auto;
				left: -20%;
				right: -20%;
				width: 130%;
			}
		}
		.text {
			position: relative;
			justify-self: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			align-self: center;
			z-index: 2;
			display: inline-block;
			text-align: center;
			font-size: 18px;
			font-weight: 600;
			line-height: 21px;
			letter-spacing: 0.04em;
			strong {
				font-size: 17px;
				font-weight: 600;
				line-height: 21px;
				letter-spacing: 0.04em;
				color: #afe7ff;
			}
		}
		.timer {
			position: relative;
			justify-self: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			align-self: center;
			z-index: 2;
		}
		.btn {
			position: relative;
			width: 200px;
			justify-self: center;
			width: 70%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			align-self: center;
			z-index: 2;
			margin-bottom: 60px;
		}
		@keyframes counter {
			0% {
				transform: scale(0.5);
			}

			100% {
				transform: scale(1);
			}
		}
	`,
);
