class NumberUtils {
	formatPriceWithDecimals(price: string | number, precision?: number) {
		if (+price === 0) {
			return 0;
		}
		if (!price) {
			return null;
		}

		const amount = typeof price === 'string' ? price : price.toString();
		const [integerPart, decimalPart] = amount.split('.');

		if (decimalPart) {
			if (typeof precision === 'number') {
				const decimal = decimalPart.length > precision ? decimalPart.substring(0, precision) : decimalPart;
				return `${Number(integerPart).toLocaleString('en')}.${decimal}`;
			} else {
				return `${Number(integerPart).toLocaleString('en')}.${decimalPart}`;
			}
		}

		return Number(amount).toLocaleString('en');
	}
	formatNumberWithSuffix(input: number, toFixed?: number) {
		if (input < 1000) {
			return input.toFixed(toFixed);
		}

		if (input >= 1000 && input < 1_000_000) {
			return `${(input / 1000).toFixed(toFixed)}K`;
		}

		if (input >= 1_000_000 && input < 1_000_000_000) {
			return `${(input / 1000 / 1000).toFixed(toFixed)}M`;
		}
		if (input >= 1_000_000_000 && input < 1_000_000_000_000) {
			return `${(input / 1000 / 1000 / 1000).toFixed(toFixed)}B`;
		}
	}
}

const numberUtils = new NumberUtils();

export default numberUtils;
