import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GamesContainer } from '../css';
// import GameCardModal from './game-card-modal';
import Coins from './coins';
import CampaignModal from './campaign-modal';
import { useGetCampaignQuery } from 'store/query';

const Games = () => {
	// const [status, setStatus] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [isCampaignLoading, setIsCampaignLoading] = useState<boolean>(false);
	const navigate = useNavigate();
	const { data: dataCampaign } = useGetCampaignQuery();
	const handleCardGame = () => {
		window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
		navigate('/card-game');
	};

	const handleCampaign = () => {
		setIsCampaignLoading(true);
		window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
		if (!isCampaignLoading) {
			dataCampaign ? navigate('campaign') : setModalOpen(true);
			setIsCampaignLoading(false);
		}
	};

	const data = [
		{
			title: 'Code',
			id: 'secret_code',
			pic: '/img/game/secret_code_popup.webp',
			onclick: () => {
				window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
				navigate('/secret-code');
			},
			background: 'default',
		},
		{ title: 'Card', id: 'card_game', pic: '/img/game-card/icon.webp', onclick: handleCardGame, background: 'default' },

		{
			title: 'Campaign',
			id: 'Pottery',
			pic: '/img/campaign/bybit.webp',
			onclick: () => {
				window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
				navigate('/pyramid');
			},
			background: 'default',
		},
		// { title: 'Campaign', id: 'Campaign', pic: '/img/new-home/bybit.webp', onclick: handleCampaign, background: 'default' },
		{
			title: 'Public Sale',
			id: 'Campaign',
			pic: dataCampaign?.iconUrl ? dataCampaign?.iconUrl : '/img/campaign/icon.webp',
			onclick: handleCampaign,
			background: dataCampaign?.colorCode ? 'custom' : 'default',
		},
	];

	return (
		<GamesContainer colorCode=''>
			{data.map((item, index) => (
				<div className={`div${index + 1} border`} id={item.id} key={`${index}-${item.title}`} onClick={item.onclick}>
					<div className={`main ${item.background === 'custom' ? 'black' : ''}`}>
						<img src={item.pic} alt='' />
						<p>{item.title}</p>
					</div>
				</div>
			))}
			<Coins />
			{/* {status && <GameCardModal status={status} setStatus={setStatus} />} */}
			{modalOpen && <CampaignModal status={modalOpen} setStatus={setModalOpen} />}
		</GamesContainer>
	);
};
export default Games;
