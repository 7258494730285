import { FC } from 'react';
import Modal from 'shared/components/modal';
import { AnswerContainerModal } from './css/answer-modal';
import Button from 'shared/components/button';
import { useNavigate } from 'react-router-dom';
import useCloseModal from 'shared/hooks/use-close-modal';
import numberUtils from 'shared/utils/number';

const AnswerModal: FC<{ status: boolean; setStatus: (status: boolean) => void; answer: boolean; coin: number }> = ({
	status,
	setStatus,
	answer,
	coin,
}) => {
	const navigate = useNavigate();
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const onCloseClickHandler = () => {
		onClose();
	};
	return (
		<Modal
			disableHeader={true}
			status={_status}
			onClose={onCloseClickHandler}
			title=''
			useBackgroundForCloseModal={false}
			isBottomSheet
		>
			<AnswerContainerModal>
				{answer ? (
					<img src='/img/academy/win.webp' alt='' className='header_img' />
				) : (
					<img src='/img/academy/cross.webp' alt='' className='header_img' />
				)}

				<h2>{answer ? 'Congratulations' : 'Oops! Not Quite Right'}!</h2>
				<p className='caption'>
					{answer ? `You answered correctly and have earned ${numberUtils.formatPriceWithDecimals(coin)} coins! ` : ' No worries'}
					<p className='caption nospace'>
						{answer ? `  Keep up the great work! 💪✨` : '  Try your chance with other questions! 💡'}
					</p>
				</p>

				<Button
					className='btn'
					variant='primary'
					onClick={() => {
						onClose();
						navigate('/academy');
					}}
				>
					Continue
				</Button>
			</AnswerContainerModal>
		</Modal>
	);
};

export default AnswerModal;
