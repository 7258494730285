import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const MaintenanceModalContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 16px;
		position: relative;
		padding: 22px;

		.header_pic {
			width: 149px;
			height: 200px;
			position: absolute;
			top: -160px;
			left: 0;
			right: 0;
			margin: 0 auto;
		}
		p {
			font-size: 16px;
			font-weight: 400;
			line-height: 20px;
		}

		.btn {
			width: 100%;
		}
	`,
);
