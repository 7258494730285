import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const GameCardModalContainer = styled.div(
	({ theme }) => css`
		position: relative;
		color: #fff;
		padding: 40px 24px 10px;
		height: 270px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		justify-content: space-around;
		.header_img {
			width: 174px;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			top: -250px;
		}
		.header_img_ {
			width: 174px;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			top: -150px;
		}
		.status_img {
			width: 62px;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			top: -140px;
			z-index: 3;
		}

		h3 {
			font-size: 24px;
			font-weight: 700;
			line-height: 29.71px;
			text-align: center;
			span {
				font-size: 20px;
				font-weight: 600;
				line-height: 24.76px;
				text-align: center;
			}
		}
		.black {
			color: #000;
		}
		h1 {
			font-size: 32px;
			font-weight: 800;
			line-height: 39.62px;
			text-align: center;

			.dollar {
				width: 25px;
				height: 25px;
				margin-bottom: 2px;
			}
		}
		p {
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			text-align: center;
		}
		button {
			width: 100%;
			text-align: center;
			border-radius: 10px;
			color: ${theme.colors.text.primary};
		}
		.btn {
			background-size: cover !important ;
			background-position: center !important ;
			box-shadow: unset !important ;
			border: unset !important ;
			background-image: url('/img/game-card/btn-larg.webp') !important ;
			text-align: center;
			border-radius: 10px;
			width: 100%;
			color: ${theme.colors.text.primary};
		}
		.reward_container {
			padding: 6px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 6px;

			.dollar_img {
				width: 48px;
				margin-left: 4px;
			}

			.value {
				color: #000;
				font-size: 30px;
				font-weight: 500;
				line-height: 37px;
			}
		}
	`,
);
