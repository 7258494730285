import { createSlice } from '@reduxjs/toolkit';
import { ILoginResponse } from 'shared/utils/request-handler';
import { IProfile } from 'store/types';

const initialState: IProfile = {
	isLogin: localStorage.getItem('access_auth_token') !== null ? true : false,
	earnPerTap: 1,
	friendsCount: 0,
	level: {
		no: 0,
		title: '',
		fromBalance: 0,
		toBalance: 0,
	},
	avatar: null,
	name: null,
	balance: 0,
	tapCount: 0,
	replay: false,
	walkthrough: localStorage.getItem('walkthrough') ? (localStorage.getItem('walkthrough') === 'true' ? true : false) : false,
	energy: null,
	profit: 0,
	notif: [],
	tasks: { pendingTasksCount: 0 },
};

export const Profile = createSlice({
	name: 'profile',
	initialState: initialState,
	reducers: {
		changeProfileLogin: (state, action: { payload: { isLogin: boolean; avatar: null | string; name: string } }) => {
			state.avatar = action.payload.avatar;
			state.isLogin = action.payload.isLogin;
			state.name = action.payload.name;
		},

		changeInitialProfile: (state, action: { payload: ILoginResponse['profile'] }) => {
			state.balance = action.payload.balance;
			state.profit = action.payload.pranaProfit;
		},

		setInitialFriendsCounts: (state, action: { payload: number }) => {
			state.friendsCount = action.payload;
		},

		changeBalance: (state, action) => {
			state.balance = action.payload.balance;
		},
		changeProfit: (state, action: { payload: number }) => {
			state.profit = action.payload;
		},

		changeReplay: state => {
			state.replay = !state.replay;
		},

		changeNotifs: (state, action) => {
			state.notif = action.payload;
		},

		changeWalkthrough: state => {
			state.walkthrough = true;
			localStorage.setItem('walkthrough', 'true');
		},
		changeTasks: (state, action) => {
			state.tasks = action.payload;
			return state;
		},
	},
});

export const {
	changeProfileLogin,
	changeInitialProfile,
	changeBalance,
	changeReplay,
	changeNotifs,
	changeWalkthrough,
	changeTasks,
	setInitialFriendsCounts,
	changeProfit,
} = Profile.actions;
export default Profile.reducer;
