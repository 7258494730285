import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const LandscapePromptContainer = styled.div(
	() => css`
		display: none;

		/* Show this overlay in portrait mode */
		@media screen and (orientation: portrait) {
			display: flex;
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			z-index: 9999;
			background: rgba(0, 0, 0, 0.7);
			color: #fff;
			justify-content: center;
			align-items: center;
			text-align: center;
			padding: 1rem;
			box-sizing: border-box;
		}
	`,
);
