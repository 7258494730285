import { useRef, useState, useEffect } from 'react';
import numberUtils from 'shared/utils/number';
import { CardGameContainer } from './css/card-game.style';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
import { EffectCards, Navigation } from 'swiper/modules';
import { Swiper as SwiperType } from 'swiper/types';
import { ArrowRightOutline } from 'shared/components/icons';
import Button from 'shared/components/button';
import AnswerModal from './components/answer-modal';
import Timer from './components/timer';
import Loader from 'shared/components/loader';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import SelectLangModal from './components/select-lang-modal';
import { useGetCardsQuery } from 'store/query';

const CardGame = () => {
	const swiperRef = useRef<SwiperType | null>(null);
	const [answerModalStatus, setAnswerModalStatus] = useState(false);
	const [cards, setCards] = useState<{ no: number; title: string; imageUrl: string; votes: number | null }[]>([]);
	const [activeCard, setActiveCard] = useState<{ no: number; title: string; imageUrl: string; votes: number | null } | null>(null);
	const [hasAnswer, setHasAnswer] = useState(false);
	const [answerChoiced, setAnswerChoiced] = useState<number>(-1);
	const [timer, setTimer] = useState(0);
	const [isLoadingAssets, setIsLoadingAssets] = useState(true);
	const [statusQuestions, setStatusQuestions] = useState<{ status: string; img: string; ep: number }[]>([]);
	const [selectedLang, setSelectedLang] = useState<'arabic' | 'english' | 'french' | 'indonesian' | 'persian' | 'russian'>('english');
	const audioRef = useRef<HTMLAudioElement | null>(null);
	const [selectLangModal, setSelectLangModal] = useState(true);
	const [sound, setSound] = useState(false);
	const navigate = useNavigate();

	const { data: cardData, isLoading, isError } = useGetCardsQuery();
	const currentData = cardData && cardData.find(item => item.startTime <= Date.now() / 1000 && item.endTime >= Date.now() / 1000);
	const nextData = cardData && currentData && cardData.find(item => item.index === currentData.index + 1);
	useEffect(() => {
		if (sound && audioRef.current) {
			audioRef.current.play();
		} else if (audioRef.current) {
			audioRef.current.pause();
		}
	}, [sound]);

	useEffect(() => {
		if (cardData && !isLoading) {
			const statusQuestionsWithImages = cardData.slice(-5).map(i => {
				const status = i.status === 3 ? 'skip' : i.status === 4 ? 'q' : i.status === 1 ? 'true' : 'false';
				return { status, img: `/img/game-card/${status}.webp`, ep: i.index };
			});
			setStatusQuestions(statusQuestionsWithImages);

			if (currentData) {
				setHasAnswer(currentData.userAnswerNo !== null);
				setAnswerChoiced(currentData.userAnswerNo ?? -1);
				setTimer(nextData !== undefined ? nextData?.startTime - Date.now() / 1000 : currentData.endTime - Date.now() / 1000);
				setCards([currentData.choice1, currentData.choice2, currentData.choice3]);
				setActiveCard(currentData.choice1);
			}

			setIsLoadingAssets(false);
		}
	}, [cardData, isLoading]);

	const onNextClickHandler = () => {
		swiperRef.current?.slideNext();
	};

	const onPrevClickHandler = () => {
		swiperRef.current?.slidePrev();
	};

	const handleSlideChange = (swiper: SwiperType) => {
		const currentSlide = cards[swiper.activeIndex];
		setActiveCard(currentSlide);
	};

	const onCloseModal = (state: boolean) => {
		setAnswerModalStatus(state);
	};

	if (isLoading) return <Loader />;
	if (isError) return <div>Error loading data...</div>;

	return (
		<CardGameContainer>
			<Helmet>
				<title>PinEye | Card Game</title>
			</Helmet>
			{currentData === undefined && (
				<>
					<img src='/img/game-card/bg.webp' alt='' className='background' />

					<h2>
						<strong>Card Game</strong>
						<br />
						<br />
						<br /> Card game is an exciting way to test your knowledge and intuition.
						<br /> <br /> Dive into the story, analyze the clues, and make your best guess to find the answer.
						<br /> <br /> Each card presents a unique challenge that will keep you engaged and entertained.
						<br />
						<br />
						<br />
						Stay tuned for next season ...
					</h2>
					<div className='button_container'>
						<Button variant='primary' className='history' onClick={() => navigate('/card-game/history')}>
							<p>Card History</p>
						</Button>
					</div>
				</>
			)}
			{isLoadingAssets && <Loader />}
			{cardData && currentData && (
				<>
					<audio ref={audioRef} src={currentData.voices[selectedLang]} loop style={{ display: 'none' }} />

					<img src='/img/game-card/bg.webp' alt='' className='background' />
					<img src='/img/game-card/top.webp' alt='' className='top' />
					<div className='header'>
						<a className='big_icon' href={currentData.infoLink} target='_blank' rel='noreferrer'>
							<img src='/img/game-card/info.webp' alt='' className='big_icon' />
						</a>
						<div className='status'>
							{statusQuestions.map((item, index) => (
								<div key={index} className='status_item'>
									<img src={item.img} className='img_status' alt='' />
									<p>EP {item.ep}</p>
								</div>
							))}
						</div>
						<div className='big_icon' onClick={() => setSound(!sound)}>
							<img
								className='big_icon'
								src={sound ? '/img/game-card/sound.webp' : '/img/game-card/muted.webp'}
								alt={sound ? 'Sound on' : 'Sound off'}
							/>
						</div>
					</div>
					<h5>
						Episode {currentData.index < 10 ? 0 : ''}
						{currentData.index}
					</h5>
					<h1>{currentData.title}</h1>
					<div className='divider' />
					<p className='question'>{currentData.question}</p>

					<div className='swiper_container'>
						<Swiper
							onSlideChange={handleSlideChange}
							navigation={{ nextEl: 'next', prevEl: 'prev' }}
							onSwiper={swiper => {
								swiperRef.current = swiper;
							}}
							effect={`cards`}
							grabCursor={true}
							modules={[EffectCards, Navigation]}
							className={`mySwiper  `}
						>
							{cards.map(card => (
								<SwiperSlide key={card.title}>
									{hasAnswer && <div className='blue' />}
									<img src={card.imageUrl} alt={card.title} className='img' />
									{card.no === answerChoiced && <p className='yes'>Your Choice</p>}
									{card.title}
								</SwiperSlide>
							))}
						</Swiper>
						<div className='prev' onClick={onPrevClickHandler}>
							<ArrowRightOutline />
						</div>
						<div className='next' onClick={onNextClickHandler}>
							<ArrowRightOutline />
						</div>
					</div>
					<div className='reward'>
						<p>
							Reward :
							<img src='/img/dollar.webp' alt='' />
							<span>{numberUtils.formatPriceWithDecimals(currentData.reward)}</span>
						</p>
					</div>
					<div className='button_container'>
						{hasAnswer ? (
							<Button variant='primary' className='lets_go_timer'>
								Next: {`   `}
								<Timer date={timer * 1000} />
							</Button>
						) : (
							<Button
								variant='primary'
								isLoading={answerModalStatus}
								className='lets_go'
								onClick={() => setAnswerModalStatus(true)}
								id='card_game_lets_go'
							>
								Choose This Card
							</Button>
						)}
						<Button variant='primary' className='history' onClick={() => navigate('/card-game/history')}>
							<p>Card History</p>
						</Button>
					</div>
				</>
			)}
			{answerModalStatus && activeCard && currentData && (
				<AnswerModal activeCard={activeCard} index={currentData.index} status={answerModalStatus} setStatus={onCloseModal} />
			)}
			{selectLangModal && (
				<SelectLangModal
					selectedLang={selectedLang}
					setSelectedLang={setSelectedLang}
					status={selectLangModal}
					setStatus={setSelectLangModal}
					setSound={setSound}
				/>
			)}
		</CardGameContainer>
	);
};

export default CardGame;
