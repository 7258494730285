import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ShowModalContainer = styled.div(
	({ theme }) => css`
		position: relative;
		color: #fff;
		padding: 20px 24px 50px;
		/* height: 270px; */
		display: flex;
		flex-direction: column;
		gap: 20px;
		justify-content: space-around;
		.header_img {
			width: 174px;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			top: -130px;
		}

		h3 {
			font-size: 17px;
			font-weight: 500;
			line-height: 21.05px;
			text-align: center;
		}

		h1 {
			font-size: 32px;
			font-weight: 600;
			line-height: 39px;
			text-align: center;
		}

		a {
			width: 100%;
			text-align: center;
			border-radius: 10px;
			color: ${theme.colors.text.primary};
			background: #f5c907 !important;
			color: #000;
		}
	`,
);
