import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const AcademyQuestionContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		padding: 54px 46px 180px;
		.img_container {
			position: relative;
			img {
				width: 100%;
				border-radius: 3px;
			}
			.play_btn {
				position: absolute;
				top: 30%;
				right: 35%;
				cursor: pointer;
				width: 88px;
				height: 88px;
			}
		}
		.title {
			font-size: 20px;
			font-weight: 600;
			line-height: 24.76px;
			text-align: center;
			margin-top: 11px;
			margin-bottom: 10px;
		}
		.description {
			font-size: 16px;
			font-weight: 400;
			line-height: 20px;
			text-align: center;
		}
		.items_container {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 20px;
			margin-top: 10px;
			margin-bottom: 30px;
			.item {
				border: 1px solid #37f09c;
				box-shadow: 0px 4px 4px 0px #00000040;
				border-radius: 8px;
				padding: 12px 8px;
				display: flex;
				gap: 8px;
				align-items: center;
				label {
					width: calc(100% - 42px);
				}
				input[type='radio'] {
					appearance: none;
					background: transparent;
					border: 2px solid #83c9ff;
					width: 16px;
					height: 16px;
					border-radius: 50%;
					outline: none;
					cursor: pointer;
					position: relative;

					&:checked {
						background-color: #000;
					}
				}
				.label_ {
					width: 100%;
				}
			}
			.item:has(input[type='radio']:checked) {
				background: #fae639;
				color: #000;
				font-weight: 600;
			}
			.item.correct {
				background: #37f09c;
				color: #000;
				font-weight: 600;
			}
			.item.incorrect {
				background: #ff4d4d;
				color: #000;
				font-weight: 600;
			}
		}
		.reward {
			justify-content: center;
			align-items: center;
			display: flex;
			span {
				font-size: 16px;
				font-weight: 600;
				line-height: 20px;
				margin: 8px 0 0;
			}
			img {
				width: 34px;
				height: 34px;
				margin-right: 8px;
				margin-left: 5px;
			}
			p {
				font-size: 27px;
				font-weight: 700;
				line-height: 33.43px;
				text-align: center;
				margin: 4px 0 0;
			}
		}
		.btn_container {
			z-index: 5;
			width: 89%;
			position: fixed;
			bottom: 110px;
			left: 0;
			right: 0;
			margin: auto;
			button {
				width: 100%;
			}
		}
	`,
);
