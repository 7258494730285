import { FC } from 'react';
import { TimerContainer } from '../css/count-down.style';
import { useCountDown } from 'shared/hooks/use-count-down';

const CountDown: FC<{ date: number }> = ({ date }) => {
	const { days, hours, minutes } = useCountDown(date - new Date().getTime());

	return (
		<TimerContainer>
			<div className='timer_scope'>
				<div className='black'>
					<div className='time'>{days.toString()[0]}</div>
					<div className='time'>{days.toString()[1]}</div>
				</div>
			</div>
			<div className='timer_scope_1'>
				<div className='black'>
					<div className='time'>{hours.toString()[0]}</div>
					<div className='time'>{hours.toString()[1]}</div>
				</div>
			</div>
			<div className='timer_scope_2'>
				<div className='black'>
					<div className='time'>{minutes.toString()[0]}</div>
					<div className='time'>{minutes.toString()[1]}</div>
				</div>
			</div>
			<p className='name'>DAYS</p>
			<p className='name'>HRS</p>
			<p className='name'>MINS</p>
		</TimerContainer>
	);
};

export default CountDown;
