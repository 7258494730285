import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const BuyContainer = styled.section(
	() => css`
		display: grid;
		grid-template-columns: 85px 85px;
		justify-content: space-between;
		align-items: center;
		color: #fff;
		position: relative;
		.cost {
			background: #041e39;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 5px;
			border-radius: 8px;
			gap: 4px;
			font-size: 11px;
			font-weight: 600;
			line-height: 14px;
			text-align: center;

			img {
				width: 16px;
				margin-bottom: 3px;
			}

			span {
				color: #f5c11d;
			}
		}
		.buy {
			font-size: 12px;
			font-weight: 600;
			line-height: 14.77px;
			text-align: center;
			z-index: 3;
		}
	`,
);
