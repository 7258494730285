import numberUtils from 'shared/utils/number';
import { AcademyContainer } from './css';
import Icon from 'shared/components/icon';
import { useNavigate } from 'react-router-dom';
import { useGetAcademiesQuery } from 'store/query/academy';
import Loader from 'shared/components/loader';

const Academy = () => {
	const navigate = useNavigate();
	const { data = [], isLoading, isError } = useGetAcademiesQuery();

	if (isLoading) return <Loader />;
	if (isError) return <div>Error loading data. Please try again later.</div>;
	if (data.length === 0) return <div>No data available.</div>;

	const dataByCategory = data.reduce<Record<string, typeof data>>((acc, item) => {
		const category = item.category;
		const completedCategory = 'Completed';

		if (item.isClaimed) {
			if (!acc[completedCategory]) acc[completedCategory] = [];
			acc[completedCategory].push(item);
		} else {
			if (!acc[category]) acc[category] = [];
			acc[category].push(item);
		}
		return acc;
	}, {});

	if (dataByCategory['Completed']) {
		const completed = dataByCategory['Completed'];
		delete dataByCategory['Completed'];
		dataByCategory['Completed'] = completed;
	}

	return (
		<AcademyContainer>
			<h1>Academy</h1>
			<p>Watch videos, answer questions, and earn coins as rewards for your learning!</p>

			{Object.entries(dataByCategory).map(([category, items]) => (
				<div className='border' key={category}>
					<div className='bg'>
						<h3>{category}</h3>
						<div className='card_container'>
							{items.map(item => (
								<div
									className='card_border'
									key={`${item.id}-${item.date}`}
									onClick={() => {
										navigate(`/academy/question/${item.id}`);
									}}
								>
									<div className='card'>
										{item.isClaimed && (
											<div className='completed'>
												<Icon
													width={42}
													name={
														item.answer?.questionItemId === item.userAnswer?.questionItemId ? 'tick_1' : 'cross'
													}
													className='icon'
												/>
											</div>
										)}
										<img className='img_top' src={item.icon} alt={item.title} />
										<div className='title'>{item.title}</div>
										<div className='border_top'>
											<div className='reward'>
												<img src='/img/academy/gift.webp' width={19} height={19} alt='reward' />
												<p>{numberUtils.formatPriceWithDecimals(item.reward)}</p>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			))}
		</AcademyContainer>
	);
};

export default Academy;
