import Button from 'shared/components/button';
import { PyramidContainer } from './css';
import { ISocialResponse } from 'api/v1/social';
import { useState } from 'react';
import { useGetSocialQuery } from 'store/query/social';
import SocialModal from 'pages/earn/components/social-modal';

const Pyramid = () => {
	const { data } = useGetSocialQuery();

	const item = data?.filter(i => i.id === 150)[0];

	const [socialModalStatus, setSocialModalStatus] = useState<{
		status: boolean;
		id: number;
		reward: number;
		title: string;
		link: string;
		isClaimed: boolean;
		modal: ISocialResponse['modal'];
	}>({
		id: 0,
		link: '',
		reward: 0,
		title: '',
		status: false,
		isClaimed: false,
		modal: {
			buttonTitle: '',
			modalIconUrl: '',
			modalText: '',
		},
	});

	return (
		<PyramidContainer>
			<Button
				onClick={() => {
					if (!item) return;

					setSocialModalStatus({
						id: item.id,
						link: item.link,
						reward: item.score,
						title: item.title,
						status: true,
						isClaimed: item.isClaimed,
						modal: item.modal,
					});
				}}
				target='_blank'
				variant='primary'
			>
				Sign Up on ByBit
			</Button>
			{socialModalStatus.status && (
				<SocialModal
					id={socialModalStatus.id}
					link={socialModalStatus.link}
					reward={socialModalStatus.reward}
					status={socialModalStatus.status}
					isClaimed={socialModalStatus.isClaimed}
					setStatus={status => {
						setSocialModalStatus({ ...socialModalStatus, status: status });
					}}
					title={socialModalStatus.title}
					modal={socialModalStatus.modal}
				/>
			)}
		</PyramidContainer>
	);
};

export default Pyramid;
