import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const ListContainer = styled.div(
	() => css`
		z-index: 2;
		position: relative;

		.list {
			padding-inline: 20px;
			display: grid;
			gap: 8px;
			color: #fff;
			.list_item {
				border: 1px solid #f5c21d;
				background: #0d5aa4;
				display: grid;
				align-items: center;
				gap: 14px;
				padding: 8px;
				border-radius: 10px;
				grid-template-columns: 44px 1fr auto;
				justify-content: space-between;
				.icon {
					width: 44px;
					height: 44px;
				}
				.coin {
					font-size: 13px;
					font-weight: 700;
					line-height: 16px;
					display: flex;
					justify-content: flex-end;
					img {
						width: 22px;
						height: 22px;
						margin-inline-end: 5px;
						margin-top: -4px;
					}
				}
				.list_title {
					color: #fff;
					font-size: 14px;
					font-weight: 600;
					line-height: 17px;
					width: 100%;
					text-align: left;
				}
			}
		}
	`,
);
