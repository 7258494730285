import { HighlightContainer } from '../css';
const Highlight = () => {
	const data = [
		{
			text: 'Airdrop Gaming Campaign ',
			checked: true,
		},
		{
			text: 'Partnership with Kucoin and Bybit ',
			checked: true,
		},
		{
			text: 'Social Trading',
			checked: true,
		},
		{
			text: 'Listing at the end of January 2025',
			checked: false,
		},
	];
	return (
		<HighlightContainer>
			<h2>What’s next</h2>
			{data.map((item, index) => {
				return (
					<div className='timeline_container' key={item.text}>
						<img src={item.checked ? '/img/wallet/checked.webp' : '/img/wallet/circle.webp'} alt='highlight' />
						{data.length - 1 > index && <div className='line' />}
						<div className='item'>
							<div>{item.text}</div>
						</div>
					</div>
				);
			})}
		</HighlightContainer>
	);
};

export default Highlight;
