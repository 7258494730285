import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TimerContainer = styled.div(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		display: grid;
		align-items: center;
		justify-content: center;
		gap: 6px;
		grid-template-columns: repeat(3, 28px);
		.name {
			font-size: 7px;
			font-weight: 400;
			line-height: 8.49px;
			text-align: center;
		}
		.timer_scope {
			display: flex;
			position: relative;
			padding: 0px;
			border-radius: 50%;
			background: #000;
			z-index: 2;
			align-items: center;
			justify-content: center;

			.black {
				display: flex;
				justify-content: center;
				border: 4px solid #000;
				border-radius: 50%;
				padding: 4px;
				min-width: 27px;
				height: 27px;
				align-items: center;
				.time {
					font-size: 12px;
					font-weight: 500;
					line-height: 18.2px;
					text-align: center;
				}
			}
			&:before {
				content: '';
				position: absolute;
				top: -2px;
				right: -2px;
				bottom: -2px;
				left: -2px;
				border-radius: 50%;
				background: conic-gradient(from 0deg, transparent 0deg, transparent 10deg, #3fc6bf 300deg, #2f9c9c 330deg, #11505e 360deg);
				animation: rotate 3s linear infinite;
				z-index: -2;
			}

			&:after {
				content: '';
				position: absolute;
				top: 0px;
				right: 0px;
				bottom: 0px;
				left: 0px;
				background: #1a4a71;
				border-radius: 50%;
				z-index: -1;
				min-width: 27px;
				height: 27px;
			}

			@keyframes rotate {
				from {
					transform: rotate(0deg);
				}
				to {
					transform: rotate(360deg);
				}
			}
		}
		.timer_scope_1 {
			display: flex;
			position: relative;
			padding: 0px;
			border-radius: 50%;
			background: #000;
			z-index: 2;
			align-items: center;
			.black {
				display: flex;
				justify-content: center;
				border: 4px solid #000;
				border-radius: 50%;
				padding: 4px;
				min-width: 27px;
				height: 27px;
				align-items: center;
				.time {
					font-size: 12px;
					font-weight: 500;
					line-height: 18.2px;
					text-align: center;
				}
			}
			&:before {
				content: '';
				position: absolute;
				top: -2px;
				right: -2px;
				bottom: -2px;
				left: -2px;
				border-radius: 50%;
				background: conic-gradient(from 0deg, transparent 0deg, transparent 10deg, #6959ce 300deg, #343e85 330deg, #0d354d 360deg);
				animation: rotate 2s linear infinite;
				z-index: -1;
			}

			&:after {
				content: '';
				position: absolute;
				top: 0px;
				right: 0px;
				bottom: 0px;
				left: 0px;
				background: #2a3273;
				border-radius: 50%;
				z-index: -1;
				min-width: 27px;
				height: 27px;
			}

			@keyframes rotate {
				from {
					transform: rotate(0deg);
				}
				to {
					transform: rotate(360deg);
				}
			}
		}
		.timer_scope_2 {
			display: flex;
			position: relative;
			padding: 0px;
			border-radius: 50%;
			background: #000;
			z-index: 2;
			align-items: center;
			.black {
				display: flex;
				justify-content: center;
				border: 4px solid #000;
				border-radius: 50%;
				padding: 4px;
				min-width: 27px;
				height: 27px;
				align-items: center;
				.time {
					font-size: 12px;
					font-weight: 500;
					line-height: 18.2px;
					text-align: center;
				}
			}
			&:before {
				content: '';
				position: absolute;
				top: -2px;
				right: -2px;
				bottom: -2px;
				left: -2px;
				border-radius: 50%;
				background: conic-gradient(from 0deg, transparent 0deg, transparent 10deg, #a958c6 300deg, #b658ce 330deg, #514c84 360deg);
				animation: rotate 3s linear infinite;
				z-index: -1;
			}

			&:after {
				content: '';
				position: absolute;
				top: 0px;
				right: 0px;
				bottom: 0px;
				left: 0px;
				background: #3a3d80;
				border-radius: 50%;
				z-index: -1;
				min-width: 27px;
				height: 27px;
			}

			@keyframes rotate {
				from {
					transform: rotate(0deg);
				}
				to {
					transform: rotate(360deg);
				}
			}
		}
	`,
);
