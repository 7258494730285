import { createCustomApi } from 'store/base.api';
import { GETCardHistory, GETCards, IGetCardsResponse } from 'api/v4/card-game';
import { AppDispatch } from 'store/types';

const apis = {
	'game-card': GETCards,
	history: GETCardHistory,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data: data };
};

const GameCardSliceApi = createCustomApi({
	reducerPath: 'game-card-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getCards: builder.query<IGetCardsResponse[], void>({
			query: () => ({ path: 'game-card' }),
		}),
		getCardHistory: builder.query<IGetCardsResponse[], void>({
			query: () => ({ path: 'history' }),
		}),
	}),
});

export const updateGameCard = (data: { index: number; userAnswerNo: number }) => (dispatch: AppDispatch) => {
	dispatch(
		GameCardSliceApi.util.updateQueryData('getCards', undefined, draft => {
			draft.forEach(item => {
				if (item.index === data.index) {
					item.userAnswerNo = data.userAnswerNo;
				}
			});
		}),
	);
};

export const { useGetCardsQuery, useGetCardHistoryQuery } = GameCardSliceApi;
export default GameCardSliceApi;
