import requestHandler from 'shared/utils/request-handler';
import { IGetAcademies, IPostClaimAcademy } from './academies.dto';

const BASE_URL = '/api/v3/academies';

export const GETAcademies = async () => {
	return requestHandler.call<IGetAcademies[]>({ url: `${BASE_URL}`, method: 'get' }).then(res => res.data);
};

export const POSTClaimAcademy = async (taskId: number, answerId: number) => {
	return requestHandler
		.call<IPostClaimAcademy>({
			url: `${BASE_URL}/${taskId}/claim?answerId=${answerId}`,
			method: 'post',
		})
		.then(res => res.data);
};
