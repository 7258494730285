import { useEffect, useState, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import Loader from 'shared/components/loader';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux-helper';
import { Pineye, Ranking, TopRanks } from './components';
import { LeaguesContainer } from './css/leagues.style';
import { getPlayersByLevelThunk } from 'store/reducers/levels';
import { useLevels } from 'shared/hooks/use-levels';

const Leagues = () => {
	const levels = useLevels();
	const currentLevel = useAppSelector(state => state.profile.level.no);
	const dispatch = useAppDispatch();
	const [activeLevel, setActiveLevel] = useState<number>(currentLevel ?? 1);
	const [loadingMore, setLoadingMore] = useState<boolean>(false);

	const onChangeActiveLeague = (type: 'NEXT' | 'PREV') => {
		if (type === 'NEXT' && activeLevel < 12) {
			setActiveLevel(prev => prev + 1);
		}
		if (type === 'PREV' && activeLevel > 1) {
			setActiveLevel(prev => prev - 1);
		}
	};

	const [players, page] = useMemo(() => {
		if (levels) {
			const level = levels.find(item => item.level === activeLevel);
			return level ? [level.players.data, level.players.page] : [[], null];
		} else {
			return [[], null];
		}
	}, [activeLevel, levels]);

	const loadPlayers = useCallback(() => {
		if (!loadingMore) {
			setLoadingMore(true);
			dispatch(
				getPlayersByLevelThunk({
					level: activeLevel,
				}),
			).finally(() => {
				setLoadingMore(false);
			});
		}
	}, [activeLevel, dispatch, players, loadingMore]);

	useEffect(() => {
		if (levels && players.length === 0 && page === -1 && !loadingMore) {
			setLoadingMore(true);
			dispatch(getPlayersByLevelThunk({ level: activeLevel ?? 1 })).finally(() => {
				setLoadingMore(false);
			});
		}
	}, [activeLevel, levels]);

	useEffect(() => {
		let debounceTimer: NodeJS.Timeout;

		const handleScroll = () => {
			if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight && !loadingMore) {
				clearTimeout(debounceTimer);
				debounceTimer = setTimeout(() => {
					loadPlayers();
				}, 100);
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
			clearTimeout(debounceTimer);
		};
	}, [loadPlayers, loadingMore]);

	return (
		<LeaguesContainer>
			<Helmet>
				<title>PinEye | Leagues</title>
			</Helmet>

			{levels && levels.length > 0 && currentLevel && players ? (
				<div className='main_container'>
					<Pineye data={levels} activeLevel={activeLevel} onChangeActiveLeague={onChangeActiveLeague} />
					<TopRanks data={players} />
					<Ranking players={players} data={levels} activeLevel={activeLevel} loadingMore={loadingMore} />
				</div>
			) : (
				<Loader />
			)}
		</LeaguesContainer>
	);
};

export default Leagues;
