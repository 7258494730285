import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const GamesContainer = styled.section(
	() => css`
		color: #fff;
		position: relative;
		display: grid;
		padding: 24px 0;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 25px;
		grid-row-gap: 20px;
		z-index: 3;
		justify-items: flex-start;
		justify-content: center;
		margin-bottom: 8px;
		.div1 {
			grid-area: 1 / 1 / 2 / 2;
		}
		.div2 {
			grid-area: 1 / 3 / 2 / 4;
		}
		.div3 {
			grid-area: 2 / 1 / 3 / 2;
		}
		.div4 {
			grid-area: 2 / 3 / 3 / 4;
		}

		.border {
			position: relative;
			width: 69px;
			height: 69px;
			.main {
				background: linear-gradient(180deg, #3cb1fa 30%, #035587 77%);
				border-radius: 12.5px;
				position: relative;
				text-align: center;
				height: inherit;
				img {
					height: 54px;
					top: -20px;
					position: relative;
					left: 0;
					right: 0;
					margin: auto;
				}
				p {
					font-size: 10px;
					font-weight: 600;
					line-height: 12.38px;
					text-align: center;
					top: -25px;
					position: relative;
					white-space: nowrap;
				}
				span {
					font-size: 17px;
					font-weight: 700;
					line-height: 21.05px;
					text-align: center;
					top: -22px;
					position: relative;
					white-space: nowrap;
				}
			}
		}
	`,
);
