import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CardGameContainer = styled.section(
	({ theme }) => css`
		position: relative;
		padding: 80px 16px 140px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 8px;
		.background {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: -webkit-fill-available;
			object-fit: cover;
			z-index: 1;
		}
		h2 {
			font-size: 23px;
			font-weight: 700;
			line-height: 26px;
			text-align: center;
			position: relative;
			z-index: 2;
			padding: 16px;
			justify-content: center;
		}
		.top {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
			z-index: 2;
		}
		.header {
			position: absolute;
			z-index: 2;
			display: grid;
			grid-template-columns: 54px auto 54px;
			align-items: center;
			top: 4px;
			gap: 16px;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
			padding: 0 12px;
			.big_icon {
				width: 54px;
			}
			.status {
				width: 100%;
				display: grid;
				grid-template-columns: repeat(5, 35px);
				gap: 6px;
				align-items: center;
				justify-content: space-between;
				.status_item {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					img {
						width: 35px;
					}
					p {
						font-family: Piedra;
						font-size: 12px;
						font-weight: 50;
						line-height: 15.61px;
						color: ${theme.colors.text.primary};
					}
				}
			}
		}
		h5 {
			text-align: center;
			font-size: 14px;
			font-weight: 400;
			line-height: 17.33px;

			position: relative;
			z-index: 2;
			padding-top: 0px;
		}
		h1 {
			font-size: 20px;
			font-weight: 600;
			line-height: 24.76px;
			text-align: center;
			position: relative;
			z-index: 2;
		}
		.divider {
			/* width: 100%; */
			height: 2px;
			background: #9b6f40;
			margin: 0px 32px 4px;
			text-align: center;
			position: relative;
			z-index: 2;
		}
		.question {
			font-size: 16px;
			font-weight: 400;
			line-height: 19.81px;
			text-align: center;
			z-index: 2;
			position: relative;
			padding-bottom: 8px;
		}

		.swiper_container {
			position: relative;
			z-index: 1;

			.swiper {
				width: 189px;
				height: 313px;
			}

			.swiper-slide {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 10px;
				font-size: 22px;
				font-weight: bold;
				box-shadow: 1px 5px 11.6px 6px #000000fc;
				.yes {
					width: 200px;
					position: absolute;
					margin: auto;
					left: 0;
					text-align: center;
					right: 0;
					color: #fff;
				}
				.img {
					position: absolute;
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 10px;
				}
				.blue {
					position: absolute;
					width: 100%;
					height: inherit;
					background: #00157154;
					z-index: 3;
					top: 0;
					left: 0;
					right: 0;
					margin: auto;
				}
			}

			.prev {
				top: 16vh;
				position: absolute;
				width: 50px;
				background: #5f5f5fc2;
				height: 50px;
				border-radius: 100%;
				left: 0;
				z-index: 6;
				border: 1px solid #000000;
				svg {
					width: 50%;
					height: 50%;
					color: #fff;
					transform: rotate(180deg);
					top: 12px;
					position: relative;
					left: 10px;
				}
			}
			.next {
				position: absolute;
				right: 0;
				width: 50px;
				height: 50px;
				top: 16vh;
				border-radius: 100%;
				background: #5f5f5fc2;
				z-index: 6;
				border: 1px solid #000000;
				svg {
					width: 50%;
					height: 50%;
					color: #fff;
					top: 12px;
					position: relative;
					left: 10px;
				}
			}
		}
		.reward {
			position: relative;
			z-index: 2;
			text-align: center;
			color: #fff;
			background-image: url('/img/game-card/reward-box.webp');
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			height: 52px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 22px 24px;

			p {
				font-weight: 500;
				font-size: 16px;
				line-height: 20px;
			}
			span {
				font-size: 20px;
				font-weight: 400;
				line-height: 20px;
			}
			img {
				width: 21px;
				margin: 0 5px -3px;
			}
		}
		.button_container {
			width: 92% !important;
			position: fixed;
			z-index: 2;
			bottom: 105px;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
			display: flex;
			justify-content: space-between;

			.lets_go {
				background-image: url('/img/game-card/btn.webp');
				width: 65%;
				height: 54px;
				font-size: 16px;
				font-weight: 600;
				line-height: 20px;
				text-align: center;
				border-radius: 10px;
				background-size: cover;
				background-position: center;
				box-shadow: unset;
				border: unset;
			}
			.lets_go_timer {
				background-image: url('/img/game-card/btn-larg.webp');
				width: 65%;
				height: 54px;
				font-size: 16px;
				font-weight: 600;
				line-height: 20px;
				text-align: center;
				border-radius: 10px;
				background-size: cover;
				background-position: center;
				box-shadow: unset;
				border: unset;
			}
			.history {
				background-image: url('/img/game-card/btn-small.webp');
				width: 34%;
				display: flex;
				flex-direction: column;
				height: 54px;
				background-size: cover;
				background-position: center;
				box-shadow: unset;
				border: unset;
				p {
					font-size: 14px;
					font-weight: 500;
					line-height: 17.33px;
					text-align: center;
					text-wrap: nowrap;
				}
				span {
					font-size: 9.7px;
					font-weight: 400;
					line-height: 12.01px;
					text-align: center;
					text-wrap: nowrap;
				}
			}
		}
		@media (min-height: 844px) {
			.button_container {
				/* top: 90px; */
			}
		}
	`,
);
