import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const ROTATE_ANIMATION = keyframes`
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
`;
export const TimerContainer = styled.div(
	({ theme }) => css`
		margin-bottom: 12px;
		--size: 38px;
		color: ${theme.colors.text.primary};
		display: grid;
		align-items: center;
		justify-content: center;
		gap: 6px;
		grid-template-columns: repeat(3, var(--size));

		.name {
			font-size: 9px;
			font-weight: 400;
			line-height: 11px;
			text-align: center;
		}
		.timer_scope {
			height: var(--size);
			display: flex;
			position: relative;
			padding: 0px;
			border-radius: 50%;
			background: #002554;
			z-index: 2;
			align-items: center;
			justify-content: center;

			.black {
				display: flex;
				justify-content: center;
				border: 4px solid #002554;
				border-radius: 50%;
				padding: 4px;
				min-width: var(--size);
				height: var(--size);
				align-items: center;
				.time {
					padding-top: 3px;
					font-size: 18px;
					font-weight: 400;
					line-height: 12px;
					text-align: center;
				}
			}
			&:before {
				content: '';
				position: absolute;
				inset: -2px;
				border-radius: 50%;
				background: conic-gradient(from 0deg, transparent 0deg, transparent 10deg, #3fc6bf 300deg, #2f9c9c 330deg, #11505e 360deg);
				animation: ${ROTATE_ANIMATION} calc((var(--index) * 1s) + 1s) linear infinite;
				z-index: -2;
			}

			&:after {
				content: '';
				position: absolute;
				top: 0px;
				right: 0px;
				bottom: 0px;
				left: 0px;
				background: #1a4a71;
				border-radius: 50%;
				z-index: -1;
				min-width: var(--size);
				height: var(--size);
			}
		}

		.timer_scope_2 {
			&::before {
				background: conic-gradient(from 0deg, transparent 0deg, transparent 10deg, #6959ce 300deg, #343e85 330deg, #0d354d 360deg);
			}
		}

		.timer_scope_3 {
			&::before {
				background: conic-gradient(from 0deg, transparent 0deg, transparent 10deg, #a958c6 300deg, #b658ce 330deg, #514c84 360deg);
			}
		}
	`,
);
