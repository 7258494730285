import { HeaderContainer } from '../css';

const Header = () => {
	return (
		<HeaderContainer>
			<h1>Invite Friends!</h1>
			<p>
				For each friend you invite, you’ll receive 500 coins. Additionally, you’ll get a 30% bonus from the taps of their friends.
			</p>
		</HeaderContainer>
	);
};
export default Header;
