import requestHandler from 'shared/utils/request-handler';
import {
	IGetPranaGameInfo,
	IGetPranaMarketplace,
	IPostPranaPurch,
	IGetPractice,
	IGetClaimPractice,
	IGetAllPractice,
	IGetPracticeDetails,
} from './prana-game.dto';

const BASE_URL = '/api/v1/PranaGame';

export const GETPranaGameInfo = async () => {
	return requestHandler.call<IGetPranaGameInfo>({ url: `${BASE_URL}/Info`, method: 'get' }).then(res => res.data);
};

export const GETPranaMarketplace = async () => {
	return requestHandler.call<IGetPranaMarketplace>({ url: `${BASE_URL}/Marketplace`, method: 'get' }).then(res => res.data);
};

export const POSTPranaPurch = async (cardId: number, level: number) => {
	return requestHandler
		.call<IPostPranaPurch>({ url: `${BASE_URL}/Purch?cardId=${cardId}&level=${level}`, method: 'post' })
		.then(res => res.data);
};

export const GETPractice = async () => {
	return requestHandler.call<IGetPractice>({ url: `${BASE_URL}/GetPractice`, method: 'get' }).then(res => res.data);
};

export const GETClaimPractice = async (id: number) => {
	return requestHandler
		.call<IGetClaimPractice>({ url: `${BASE_URL}/ClaimPractice?practiceId=${id}`, method: 'get' })
		.then(res => res.data);
};

export const GETAllPractice = async () => {
	return requestHandler.call<IGetAllPractice>({ url: `${BASE_URL}/GetAllPractices`, method: 'get' }).then(res => res.data);
};

export const GETPracticeDetails = async (practiceId: number) => {
	return requestHandler
		.call<IGetPracticeDetails>({ url: `${BASE_URL}/GetPracticeDetails?practiceId=${practiceId}`, method: 'get' })
		.then(res => res.data);
};
