import { GETChristmas, IGetChristmasResponse } from 'api/v3/christmas';
import { createCustomApi } from 'store/base.api';
import { AppDispatch } from 'store/types';

const apis = {
	christmas: GETChristmas,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data: data };
};

const ChristmasSliceApi = createCustomApi({
	reducerPath: 'christmas-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getChristmas: builder.query<IGetChristmasResponse, void>({
			query: () => ({ path: 'christmas' }),
		}),
	}),
});

export const updateChristmas = (data: { canGetReward: boolean }) => (dispatch: AppDispatch) => {
	dispatch(
		ChristmasSliceApi.util.updateQueryData('getChristmas', undefined, draft => {
			draft.canGetReward = data.canGetReward;
		}),
	);
};

export const { useGetChristmasQuery } = ChristmasSliceApi;
export default ChristmasSliceApi;
