import { useMatomo } from '@datapunt/matomo-tracker-react';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
// import Boost from 'pages/boost';
// import CardGame from 'pages/card-game';
import Earn from 'pages/earn';
import Exchange from 'pages/exchange';
// import Friends from 'pages/friends';
import Home from 'pages/home';
// import Leagues from 'pages/leagues';
// import Lottery from 'pages/lottery';
// import Miner from 'pages/miner';
import Practice from 'pages/practice';
import PranaShop from 'pages/prana-shop';
import RewardHistory from 'pages/reward-history';
// import Shop from 'pages/shop';
// import Wallet from 'pages/wallet';
import Wallet from 'pages/wallet-v2';
// import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
// import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from 'shared/components/layout/layout';
import { envResolver } from 'shared/configs/env-resolver';
// import { CardHistory } from 'pages/card-history';
// import { SecretCode } from 'pages/secret-code';
import Campaign from 'pages/campaign/campaign';
// import Academy from 'pages/academy';
// import Pyramid from 'pages/pyramid';
import { AcademyQuestion } from 'pages/academy-question';
// import { CampaignBybit } from 'pages/bybit-campaign';
// import { Christmas } from 'pages/christmas';
import { disconnectSocket, initializeSocket } from './socket';
import Telegram from 'shared/utils/telegram';
import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux-helper';
import { resetSocket } from 'store/reducers/socket';
import Vip from 'pages/vip';
import AvatarShop from 'pages/avatar-shop';
import MapPage from 'pages/map';
const manifestUrl = envResolver.TON_MANIFEST;

const App = () => {
	const { enableLinkTracking } = useMatomo();
	const { userId } = Telegram().getTelegramData();
	const balance = useAppSelector(state => state.profile.balance);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const socketRef = useRef<any | null>(null);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (envResolver.MODE === 'STAGE' && userId && balance) {
			socketRef.current = initializeSocket(`${userId}`, dispatch);
		}

		return () => {
			disconnectSocket();
			dispatch(resetSocket());
		};
	}, [userId, balance, dispatch]);

	enableLinkTracking();
	return (
		<TonConnectUIProvider
			actionsConfiguration={{
				twaReturnUrl: 'https://t.me/pineye_bot',
			}}
			manifestUrl={manifestUrl}
		>
			<BrowserRouter>
				<Toaster
					position='top-center'
					containerStyle={{
						zIndex: 9999999999,
					}}
					containerClassName='toaster_container'
				/>
				<Layout>
					<Routes>
						<Route path='/' element={<Home />} />
						<Route path='/earn' element={<Earn />} />
						<Route path='/reward-history' element={<RewardHistory />} />
						<Route path='/wallet' element={<Wallet />} />
						<Route path='/exchange' element={<Exchange />} />
						<Route path='/prana/shop' element={<PranaShop />} />
						<Route path='/games/prana/practice/:id' element={<Practice />} />
						<Route path='/campaign' element={<Campaign />} />
						<Route path='/academy/question/:id' element={<AcademyQuestion />} />
						<Route path='/vip' element={<Vip />} />
						<Route path='/avatar-shop' element={<AvatarShop />} />
						<Route path='/map' element={<MapPage />} />
					</Routes>
				</Layout>
			</BrowserRouter>
		</TonConnectUIProvider>
	);
};

export default App;
