import { css } from '@emotion/react';
import styled from '@emotion/styled';
// import { ArrowDown, ArrowRight } from 'shared/components/icons';

export const TabsContainer = styled.div(
	() => css`
		display: flex;
		align-items: center;
		justify-content: center;
		background: linear-gradient(to bottom, #ffffff, #f0f0f0);
		border-radius: 30px;
		padding: 1px;
		margin: 0 auto;
		position: relative;
		top: 30px;
		@media (max-width: 350px) {
			top: 20px;
		}
	`,
);

export const Tab = styled.button<{ isActive: boolean }>`
	flex: 1;
	background: ${({ isActive }) => (isActive ? '#00244a' : '#ffffff')};
	color: ${({ isActive }) => (isActive ? '#FFE540' : '#00244a')};
	border: none;
	border-radius: 30px;
	padding: 10px 20px;
	font-size: 16px;
	font-weight: bold;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease;

	&:hover {
		background: ${({ isActive }) => (isActive ? '#00244a' : '#f0f0f0')};
	}

	.icon {
		margin-left: 10px;
		font-size: 12px;
	}
`;
