import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ButtonContainer = styled.button(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		appearance: none;
		border: 0.66px solid #000000;
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 3px;
		position: relative;
		&.disabled {
			opacity: 0.7;
		}
		.node {
			position: relative;
			top: 8px;
		}
		&.secondary {
			background: #115b88;
			padding-bottom: 3.5px;
		}
		&.primary {
			background: #348421;
			padding-bottom: 3.5px;
		}
		&.thirty {
			background: #fcc00f;
			padding-bottom: 3.5px;
		}
		.second_primary {
			background: #8de865;
			padding: 0 3px 3px 1px;
			width: 100%;
			border-radius: 4px;
		}
		.second_thirty {
			background: #fff9d6;
			padding: 0 3px 3px 1px;
			width: 100%;
			border-radius: 4px;
		}
		.second_secondary {
			background: #91d2fa;
			padding: 0 3px 3px 1px;
			width: 100%;
			border-radius: 4px;
		}
		.third_primary {
			background: #2fb106;
			padding-bottom: 10px;
			width: 100%;
			border-radius: 4px;
		}
		.third_secondary {
			background: #3893cb;
			padding-bottom: 10px;
			padding-inline-end: 1px;
			width: 100%;
			border-radius: 4px;
		}
		.third_thirty {
			background: #ffe540;
			padding-bottom: 10px;
			width: 100%;
			border-radius: 4px;
		}
		.forth_primary {
			background: #4bc61e;
			padding: 2px;
			width: 100%;
			border-radius: 4px;
			padding-inline-end: 1px;
		}
		.forth_secondary {
			background: #67bef3;
			padding: 2px;
			width: 100%;
			border-radius: 4px;
		}
		.forth_thirty {
			background: #ffcc37;
			padding: 2px;
			width: 100%;
			border-radius: 4px;
			padding-inline-end: 1px;
		}
	`,
);
