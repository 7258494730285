import { useEffect, useState } from 'react';
import { CardHistoryContainer } from './css';
import { IGetCardsResponse } from 'api/v4/card-game';
import Button from 'shared/components/button';
import { useNavigate } from 'react-router-dom';
import Loader from 'shared/components/loader';
import { useGetCardsQuery } from 'store/query';

const CardHistory = () => {
	const [cardHistory, setCardHistory] = useState<IGetCardsResponse[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const { data } = useGetCardsQuery();

	const navigate = useNavigate();
	useEffect(() => {
		window.Telegram.WebApp.BackButton.onClick(() => {
			navigate('/card-game');
		});
		return () => {
			window.Telegram.WebApp.BackButton.onClick(() => {
				navigate('/');
			});
		};
	}, []);
	useEffect(() => {
		if (data && data?.length > 0) {
			const history = data.filter(i => i.status !== 4);
			setCardHistory(history);

			const imageUrls = [
				// '/img/game-card/background.webp',
				// '/img/game-card/line.webp',
				// '/img/game-card/yes.webp',
				// '/img/game-card/no.webp',
				// '/img/game-card/passed.webp',
				...data.map(i => i.choice1.imageUrl),
				...data.map(i => i.choice2.imageUrl),
				...data.map(i => i.choice3.imageUrl),
			];

			Promise.all(
				imageUrls.map(
					src =>
						new Promise(resolve => {
							const img = new Image();
							img.src = src;
							img.onload = resolve;
							img.onerror = resolve;
						}),
				),
			).then(() => setIsLoading(false));
		} else setIsLoading(false);
	}, [data]);

	return isLoading ? (
		<Loader />
	) : (
		<CardHistoryContainer>
			<img src='/img/game-card/bg.webp' className='background' alt='card-history' />

			<h1>Card History</h1>

			{cardHistory.map((item, index) => (
				<>
					<div className='levels' key={`${index}-card-history-1-${item.question}`}>
						<div className='divider' />
						<h2>
							Episode {item.index < 10 ? 0 : ''}
							{item.index}
						</h2>
						<div className='items' key={`${index}-card-history-item-2`}>
							<div className={`item   `} key={`${index}-card-history-item-3`}>
								<img
									src={item.choice1.imageUrl}
									alt=''
									className={`card_img ${
										item.answerNo === item.userAnswerNo && item.answerNo === item.choice1.no
											? 'correct'
											: item.userAnswerNo === item.choice1.no
											? 'wrong'
											: ''
									}`}
								/>
								{item.answerNo === item.choice1.no && <img src='/img/game-card/true.webp' className='answer_pic' alt='' />}
								{item.userAnswerNo === item.choice1.no && <p className='your_choice'>Your Choice</p>}
								<p className='name'>{item.choice1.title}</p>
								<strong>{item.choice1.votes?.toFixed(2)}% Votes</strong>
							</div>
							<div className='item' key={`${index}-card-history-item-4`}>
								<img
									src={item.choice2.imageUrl}
									alt=''
									className={`card_img ${
										item.answerNo === item.userAnswerNo && item.answerNo === item.choice2.no
											? 'correct'
											: item.userAnswerNo === item.choice2.no && 'wrong'
									}`}
								/>
								{item.answerNo === item.choice2.no && <img src='/img/game-card/true.webp' className='answer_pic' alt='' />}
								{item.userAnswerNo === item.choice2.no && <p className='your_choice'>Your Choice</p>}
								<p className='name'>{item.choice2.title}</p>
								<strong>{item.choice2.votes?.toFixed(2)}% Votes</strong>
							</div>
							<div className='item' key={`${index}-card-history-item-5`}>
								<img
									src={item.choice3.imageUrl}
									alt=''
									className={`card_img ${
										item.answerNo === item.userAnswerNo && item.answerNo === item.choice3.no
											? 'correct'
											: item.userAnswerNo === item.choice3.no && 'wrong'
									}`}
								/>
								{item.answerNo === item.choice3.no && <img src='/img/game-card/true.webp' className='answer_pic' alt='' />}
								{item.userAnswerNo === item.choice3.no && <p className='your_choice'>Your Choice</p>}
								<p className='name'>{item.choice3.title}</p>
								<strong>{item.choice3.votes?.toFixed(2)}% Votes</strong>
							</div>
						</div>
					</div>
				</>
			))}
			{cardHistory && (
				<div className='levels'>
					<div className='divider' />
					<h2>
						Episode {cardHistory?.slice(-1)[0]?.index < 9 ? 0 : ''}
						{cardHistory?.slice(-1)[0]?.index + 1}
					</h2>
					<div className='items'>
						<div className='item  '>
							<img src={'/img/game-card/q-img.webp'} alt='' className='card_img' />
						</div>
						<div className='item'>
							<img src={'/img/game-card/q-img.webp'} alt='' className='card_img' />
						</div>
						<div className='item'>
							<img src={'/img/game-card/q-img.webp'} alt='' className='card_img' />
						</div>
					</div>
				</div>
			)}

			<div style={{ width: '100%', height: '200px' }} />
			<Button variant='primary' onClick={() => navigate('/card-game')} className='start_btn'>
				Start Quest
			</Button>
		</CardHistoryContainer>
	);
};

export default CardHistory;
