import { IGetPlayersResponse, ILevelResponse } from 'api/v2/levels';
import { FC } from 'react';
import numberUtils from 'shared/utils/number';
import { RankingContainer } from '../css';
import DotLoader from 'shared/components/dot-loader';

interface RankingProps {
	activeLevel: number;
	data: ILevelResponse[];
	players: IGetPlayersResponse[];
	loadingMore: boolean;
}

const Ranking: FC<RankingProps> = ({ data, activeLevel, players, loadingMore }) => {
	return (
		<RankingContainer className={` style_${activeLevel}`}>
			<div className='info '>
				<div className='border'>
					<div>Level {activeLevel} </div>
					<div>
						<span>Friends:</span>
						{activeLevel === 12
							? ` from ${
									data[activeLevel - 1].fromFriendCount < 5000
										? numberUtils.formatPriceWithDecimals(data[activeLevel - 1].fromFriendCount, 0)
										: numberUtils.formatNumberWithSuffix(data[activeLevel - 1].fromFriendCount, 0)
							  }`
							: ` from ${
									data[activeLevel - 1].fromFriendCount < 5000
										? numberUtils.formatPriceWithDecimals(data[activeLevel - 1].fromFriendCount, 0)
										: numberUtils.formatNumberWithSuffix(data[activeLevel - 1].fromFriendCount, 0)
							  } to ${
									data[activeLevel - 1].toFriendCount < 5000
										? numberUtils.formatPriceWithDecimals(data[activeLevel - 1].toFriendCount, 0)
										: numberUtils.formatNumberWithSuffix(data[activeLevel - 1].toFriendCount, 0)
							  }`}
					</div>
					<div>{` ${numberUtils.formatNumberWithSuffix(data[activeLevel - 1].fromBalance)}-${
						numberUtils.formatNumberWithSuffix(data[activeLevel - 1].toBalance) === undefined
							? '∞'
							: numberUtils.formatNumberWithSuffix(data[activeLevel - 1].toBalance)
					}`}</div>
				</div>
			</div>
			{players.slice(3).map((item, index) => (
				<div className='rank_item ' key={`rank_item_${index}`}>
					<div className='border'>
						<div className='number'>{item.no}</div>
						<div className='img_container'>
							<img src={`/img/pineye/level-${activeLevel ?? 1}.webp`} width={30} height={30} alt='' />
						</div>
						<div className='username'>
							{item.userName}
							<div className='balance'>
								<img src='/img/dollar.webp' alt='' />
								<span>{numberUtils.formatPriceWithDecimals(item.balance)}</span>
							</div>
						</div>
					</div>
				</div>
			))}
			{loadingMore && <DotLoader />}
		</RankingContainer>
	);
};
export default Ranking;
