import requestHandler from 'shared/utils/request-handler';
import { IGetCardsResponse, IPostResponse } from './card-game.dto';

const BASE_URL = '/api/v4/Game';

export const GETCards = async () => {
	return requestHandler.call<IGetCardsResponse[]>({ url: `${BASE_URL}/GetCards`, method: 'get' }).then(res => res.data);
};

export const POSTSendAnswer = async (index: number, answerNo: number) => {
	return requestHandler
		.call<IPostResponse>({ url: `${BASE_URL}/${index}/setAnswer?answerNo=${answerNo}`, method: 'post' })
		.then(res => res.data);
};

export const GETCardHistory = async () => {
	return requestHandler.call<IGetCardsResponse[]>({ url: `${BASE_URL}/GetCardHistory`, method: 'get' }).then(res => res.data);
};
